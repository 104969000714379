import PropTypes from 'prop-types';
import './style.scss';

const HeaderLink = ({url, children}) => {
	return (
		<a className="xCubeApp-HeaderLink" href={url}>
			{children}
		</a>
	);
};

HeaderLink.propTypes = {
	url: PropTypes.string,
	children: PropTypes.node,
};

export const VisatLogo = ({visatUrl}) => {
	return (
		<HeaderLink url={visatUrl}>
			<div className="xCubeApp-Logo">visat</div>
		</HeaderLink>
	);
};

VisatLogo.propTypes = {
	visatUrl: PropTypes.string,
};

export const Divider = () => {
	return <span className="xCubeApp-Header-divider">/</span>;
};
