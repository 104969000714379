import {create as createRouter} from '@gisatcz/ptr-router';

// base styles need to be imported before all components
// import '@gisatcz/ptr-core/lib/styles/reset.css';
// import '@gisatcz/ptr-core/lib/styles/base.scss';
import Actions from './state/Action';
import Select from './state/Select';
import {getStoreFromRequest} from './state/router/utils';
// import './styles/LayerSettings.scss';

let router;

function createRoutes() {
	return {
		'': {
			name: 'home',
			handler: request => {
				const store = getStoreFromRequest(request);
				const dispatch = store.dispatch;
				const isXCubeApp = Select.isXCubeApp();
				const viewKey = Select.views.getActiveKey(store.getState());
				if (isXCubeApp) {
					dispatch(Actions.router.updateAppUrl('app', {viewKey}));
				}

				if (window.location.pathname !== '/') {
					dispatch(Actions.router.updateAppUrl('home'));
				}
			},
		},
		'/app': {
			name: 'app',
			handler: () => {},
		},
	};
}

/**
 * Creates router instance that can be used to manipulate urls.
 *
 * App handler updates store with current page and it's up to views to react to the change.
 * In case of url change, redux selector possibly retrieves different layers and passes them
 * into some the component.
 *
 */
const initRouter = (absPath, currentUrl, Store, isPreloaded, navHandler) => {
	router = createRouter({
		rootUrl: absPath,
		currentUrl,
		routes: createRoutes(),
		navHandler,
		store: Store,
		generateUrlsOptions: {
			stringifyQueryParams: params => new URLSearchParams(params).toString(),
		},
	});

	return router;
};

const getRouter = () => router;

export {getRouter, initRouter, createRoutes};
