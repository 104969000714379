import PropTypes from 'prop-types';
import {useEffect} from 'react';
import Report from '../Report';
import Explorer from '../Explorer';
import Benchmark from '../Benchmark';
import XCube from '../XCube';

import './style.scss';

const ProductViewerScreen = ({
	viewKey,
	onViewKeyChanged,
	onUnmount,
	onMount,
	setDefaultViewKey,
	view,
}) => {
	useEffect(() => {
		onMount(viewKey);
		return onUnmount;
	}, []);

	useEffect(() => {
		if (viewKey) {
			onViewKeyChanged(viewKey);
		} else {
			setDefaultViewKey();
		}
	}, [viewKey]);

	const getComponentsForView = viewNameInternal => {
		switch (viewNameInternal) {
			case 'explore':
				return <Explorer />;
			case 'benchmark':
				return <Benchmark />;
			case 'report':
				return <Report />;
			case 'xcube':
				return <XCube />;

			default:
				return null;
		}
	};

	const viewComponents = getComponentsForView(view?.data?.nameInternal);

	return <div className={'unhab-ProductViewerScreen'}>{viewComponents}</div>;
};

ProductViewerScreen.propTypes = {
	onViewKeyChanged: PropTypes.func,
	setDefaultViewKey: PropTypes.func,
	onUnmount: PropTypes.func,
	onMount: PropTypes.func,
	viewKey: PropTypes.string,
	view: PropTypes.any,
};

export default ProductViewerScreen;
