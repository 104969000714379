import PropTypes from 'prop-types';
import classnames from 'classnames';
import {cloneElement, useState, Children} from 'react';
import './style.scss';

const MapLayerLegend = ({children, expandable = true}) => {
	const [isExpanded, setExpanded] = useState(false);
	const classes = classnames('ptr-MapLayerLegend', {
		'is-expanded': isExpanded,
		expandable,
	});

	return (
		<div
			onClick={() => (expandable ? setExpanded(!isExpanded) : null)}
			className={classes}
		>
			{Children.map(children, child =>
				child ? cloneElement(child, {expanded: isExpanded}) : null,
			)}
		</div>
	);
};

MapLayerLegend.propTypes = {
	children: PropTypes.node,
	expandable: PropTypes.bool,
};

export default MapLayerLegend;
