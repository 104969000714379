import './style.scss';
const Logo = () => (
	<div className="unhab-Header-logo">
		<svg
			version="1.1"
			x="0px"
			y="0px"
			width="557.5px"
			height="107.528px"
			viewBox="0 0 557.5 107.528"
		>
			<g>
				<g>
					<path
						d="M174.704,40.804c-0.359,0.098-0.766,0.113-1.16,0.141c-1.047,0.078-2.219,0.023-3.301,0.078
			c-0.348,0.016-0.723-0.031-1.043,0.047c-0.109,0.027-0.215,0.113-0.305,0.195c-0.152,0.164-0.223,0.375-0.273,0.586
			c-0.148,0.613-0.113,1.402-0.133,2.07c-0.031,0.559-0.059,1.117-0.09,1.676c-0.016,0.898,0.035,1.789,0.09,2.629
			c0.027,0.438,0.02,1.047,0.309,1.363c0.316,0.352,1.523,0.312,2.078,0.312c0.117-0.039,0.258-0.039,0.395-0.039
			c0.078-0.012,0.164-0.027,0.25-0.047c0.242-0.074,0.484-0.148,0.723-0.223c0.469-0.148,0.926-0.352,1.371-0.551
			c0.641-0.332,1.285-0.664,1.93-0.996c0.277-0.152,0.559-0.301,0.832-0.457c0.156-0.156,0.312-0.309,0.473-0.465
			c0.242-0.242,0.543-0.449,0.828-0.629c0.828-0.551,1.73-0.965,2.461-1.672c0.402-0.395,0.609-0.934,0.934-1.371
			c0.18-0.23,0.785-1.152,0.75-1.402c-0.016-0.145-0.133-0.195-0.266-0.219c-1.922-0.371-3.887-0.578-5.914-0.766
			C175.293,41.034,175.083,40.815,174.704,40.804 M157.86,30.163c-0.117,0.039-0.23,0.066-0.328,0.145
			c-0.16,0.137-0.258,0.359-0.363,0.531c-0.195,0.305-0.387,0.609-0.578,0.914c-0.109,0.305-0.227,0.613-0.34,0.918
			c-0.102,0.219-0.258,0.387-0.375,0.598c-0.164,0.305-0.254,0.645-0.418,0.949c-0.309,0.566-0.73,1.098-1.023,1.676
			c-0.094,0.184-0.211,0.359-0.246,0.562c-0.098,0.523,0.098,0.75,0.621,0.75c0.141-0.008,0.273-0.016,0.406-0.02
			c0.094-0.012,0.184-0.016,0.277-0.023c0.066-0.008,0.137-0.016,0.203-0.023c0.102-0.008,0.203-0.016,0.301-0.023
			c0.051-0.008,0.105-0.012,0.16-0.023c0.125-0.004,0.254-0.016,0.383-0.02c0.168-0.008,0.336-0.012,0.5-0.02
			c0.484-0.145,1.168,0.062,1.684,0.062c0.094-0.012,0.184-0.027,0.277-0.043c0.121-0.02,0.238-0.043,0.359-0.062
			c0.301-0.137,0.914,0.02,1.281,0.02c0.086-0.008,0.176-0.012,0.27-0.02c0.047-0.008,0.094-0.016,0.133-0.023
			c0.098-0.012,0.184-0.02,0.277-0.023c0.242-0.008,0.484-0.016,0.73-0.023c0.039-0.008,0.086-0.016,0.133-0.023
			c0.082-0.043,0.16-0.09,0.246-0.137c0.195-0.219,0.141-1.07,0.141-1.406c0-0.551-0.055-1.145-0.062-1.68
			c-0.012-0.383,0.055-0.855-0.078-1.18c-0.098-0.242-0.387-0.438-0.594-0.578c-0.367-0.246-0.77-0.492-1.18-0.672
			c-0.453-0.156-0.906-0.312-1.355-0.469C158.821,30.589,158.415,30.229,157.86,30.163 M173.7,30.03
			c-0.629,0.258-1.215,0.645-1.801,1.012c-1.043,0.656-2.289,1.234-2.922,2.344c-0.242,0.414-0.367,1.422-0.418,1.945
			c-0.02,0.242-0.172,1.012,0,1.188c0.047,0.027,0.094,0.055,0.137,0.082c0.242,0.109,0.551,0.07,0.84,0.082
			c0.66,0.02,1.32,0.043,1.98,0.062c0.523,0.023,1.031,0.148,1.574,0.191c0.848,0.023,1.699,0.051,2.551,0.078
			c0.316,0.031,0.637,0.066,0.949,0.102v-0.023c0.32-0.07,0.637-0.238,0.984-0.238c0.055-0.008,0.105-0.016,0.16-0.023
			c0.23-0.129,0.555-0.285,0.516-0.617c-0.016-0.227-0.23-0.453-0.332-0.645c-0.426-0.801-0.93-1.391-1.508-2.117
			c-0.305-0.453-0.613-0.906-0.922-1.359c-0.188-0.25-0.406-0.48-0.613-0.723c-0.07-0.141-0.148-0.273-0.223-0.414
			c-0.133-0.262-0.309-0.527-0.512-0.762C174.024,30.046,173.899,30.042,173.7,30.03 M169.77,24.597
			c-0.066,0.426,0.02,0.91,0.047,1.324c0.008,0.145,0.051,0.293,0.02,0.438c-0.125,0.402-0.238,0.809-0.363,1.211
			c-0.121,0.227-0.367,0.363-0.43,0.625c-0.043,0.18,0.105,0.242,0.266,0.242c0.609-0.359,2.32-0.988,1.914-1.977
			c-0.145-0.355-0.469-0.574-0.672-0.902C170.352,25.237,170.215,24.683,169.77,24.597 M164.891,19.323
			c-0.52,0.164-0.836,0.465-1.242,0.863c-0.141,0.121-0.273,0.246-0.414,0.367c-0.113,0.176-0.234,0.348-0.355,0.523
			c-0.16,0.18-0.316,0.359-0.48,0.543c-0.121,0.191-0.254,0.391-0.383,0.582c-0.133,0.133-0.27,0.262-0.406,0.398
			c-0.078,0.176-0.148,0.355-0.23,0.531c-0.359,0.578-0.625,1.168-0.938,1.766c-0.105,0.191-0.297,0.32-0.387,0.52
			c-0.105,0.293-0.215,0.586-0.32,0.871c-0.117,0.273-0.523,0.812-0.422,1.129s0.879,0.523,1.16,0.656
			c1.406,0.664,2.754,1.398,4.375,1.652c0.223,0.031,0.402,0.129,0.66,0.129c0.055-0.012,0.109-0.016,0.16-0.023
			c0.043-0.008,0.086-0.016,0.133-0.023c0.086-0.016,0.168-0.027,0.254-0.043c0.078-0.016,0.148-0.027,0.23-0.043
			c0.203-0.129,0.5-0.293,0.5-0.578c-0.367-0.25-0.793-0.129-1.258-0.156c-1.062-0.066-1.383-0.312-2.129-0.949
			c-0.156-0.129-0.301-0.254-0.445-0.379c-0.066-0.137-0.125-0.27-0.184-0.41c-0.316-0.656-0.656-1.477-0.363-2.246
			c0.121-0.32,0.395-0.551,0.555-0.844c0.145-0.281,0.137-0.645,0.32-0.906c0.23-0.32,1.137-0.863,1.504-1.008
			c0.176-0.047,0.348-0.102,0.512-0.148c0.16-0.066,0.281-0.168,0.465-0.203c0.422-0.07,0.977,0.008,1.344,0.137
			c0.27,0.094,0.594,0.246,0.906,0.246v-0.02h0.02C167.926,21.413,165.786,19.347,164.891,19.323 M164.004,11.878
			c-0.605,0.16-1.355,0-1.977,0.129c-0.246,0.094-0.488,0.199-0.734,0.297c-0.516,0.203-1.062,0.355-1.637,0.441
			c-0.309,0.027-0.621,0.051-0.934,0.078c-0.141,0.004-0.273,0.012-0.414,0.016c-0.121,0.098-0.242,0.199-0.355,0.293
			c-0.203,0.16-0.418,0.289-0.633,0.406c-0.496,0.254-1.066,0.398-1.531,0.699c-0.293,0.234-0.582,0.469-0.875,0.699
			c-0.195,0.137-0.434,0.172-0.625,0.297c-0.309,0.246-0.617,0.496-0.926,0.738c-0.602,0.457-1.223,0.883-1.801,1.391
			c-0.398,0.348-0.672,0.758-1.027,1.102c-0.129,0.098-0.254,0.195-0.383,0.293c-0.297,0.219-0.586,0.504-0.816,0.793
			c-0.367,0.449-0.668,0.922-0.922,1.422c-0.191,0.379-0.238,0.77-0.543,1.086c-0.348,0.359-0.703,0.617-1.129,0.828
			c-0.234,0.117-0.492,0.16-0.691,0.352c-0.555,0.516-0.652,1.449-1.078,2.062c-0.434,0.621-1.18,1.066-1.637,1.68
			c-0.148,0.203-0.246,0.434-0.348,0.66c-0.535,1.273-0.742,2.699-0.938,4.133c-0.07,0.516-0.137,1.027-0.203,1.543
			c0.02,0.219,0.031,0.434,0.051,0.645c0.012,1,0.16,1.852,0.379,2.742c0.16,0.699,0.32,1.375,0.59,2.023
			c0.281,0.656,0.77,1.102,1.152,1.699c0.277,0.43,0.48,0.883,0.785,1.301c0.16,0.219,0.375,0.391,0.555,0.605
			c0.457,0.547,0.914,1.082,1.465,1.602c0.906,0.855,1.941,1.523,2.969,2.18c0.695,0.449,1.391,0.926,2.152,1.246
			c1.004,0.422,2.082,0.766,3.133,1.09c0.504,0.094,1.004,0.188,1.512,0.281c1.344,0.281,2.66,0.512,4.16,0.512
			c0.203-0.094,0.594-0.086,0.82-0.086c0.051-0.008,0.094-0.02,0.137-0.027c0.082-0.012,0.168-0.027,0.25-0.039
			c0.273-0.145,0.531-0.109,0.672-0.426c0.07-0.25,0.141-0.5,0.203-0.746c0.004-1.137,0.012-2.27,0.012-3.398
			c0-0.777,0-1.52-0.059-2.254c-0.031-0.352-0.02-0.73-0.117-1.059c-0.02-0.07-0.086-0.129-0.145-0.176
			c-0.301-0.215-1.992-0.012-2.434,0.016c-0.199-0.008-0.391-0.012-0.586-0.016c-0.238-0.051-0.449-0.168-0.711-0.195
			c-0.547-0.051-0.984,0.188-1.477,0.195c-0.43,0.008-0.848-0.156-1.305-0.129c-0.34,0.02-0.664,0.062-0.957,0.184
			c-0.379,0.156-0.684,0.383-1.09,0.5c-1.062,0.289-2.289,0.027-3.035-0.656c-0.328-0.297-0.562-0.668-0.801-1.012
			c-0.164-0.223-0.324-0.445-0.488-0.668c-0.164-0.355-0.098-0.922,0.062-1.246c0.098-0.199,0.266-0.305,0.406-0.473
			c0.23-0.289,0.398-0.613,0.656-0.891c0.371-0.371,0.734-0.738,1.102-1.117c0.203-0.441,0.41-0.891,0.605-1.336
			c0.137-0.25,0.391-0.465,0.5-0.723c0.109-0.266,0.117-0.59,0.234-0.867c0.18-0.43,0.484-0.805,0.688-1.223
			c0.145-0.352,0.289-0.707,0.434-1.062c0.215-0.387,0.5-0.758,0.625-1.195c0.043-0.16,0.055-0.328-0.02-0.469
			c-0.078-0.125-0.184-0.238-0.305-0.324c-0.336-0.219-0.766-0.301-1.113-0.512c-0.762-0.457-1.426-1.156-1.816-1.926
			c-0.066-0.133-0.18-0.309-0.168-0.469c0.02-0.254,0.238-0.484,0.402-0.676c0.27-0.316,0.797-0.672,1.238-0.672
			c0.074-0.016,0.148-0.031,0.227-0.047c1.145-0.375,2.762,2.121,3.441,1.543c0.281-0.238,0.359-0.723,0.512-1.039
			c0.273-0.48,0.543-0.957,0.816-1.438c0.176-0.344,0.348-0.684,0.523-1.027c0.41-0.676,0.816-1.352,1.227-2.031
			c0.121-0.164,0.25-0.328,0.375-0.488c0.16-0.234,0.207-0.566,0.375-0.816c0.301-0.453,0.711-0.852,0.965-1.328
			c0.074-0.18,0.148-0.359,0.215-0.539c0.078-0.109,0.156-0.223,0.23-0.328c0.137-0.223,0.25-0.445,0.355-0.68
			c0.156-0.457,0.316-0.91,0.477-1.371c0.141-0.41,0.332-0.793,0.52-1.184c0.207-0.414,0.723-0.684,0.918-1.098
			C164.477,11.917,164.231,11.882,164.004,11.878 M170.059,11.854c-0.316,0.082-1.16,0.383-1.133,0.789
			c0.012,0.047,0.027,0.082,0.039,0.125c0.316,0.395,0.633,0.781,0.945,1.172c0.266,0.363,0.41,0.777,0.648,1.148
			c0.156,0.25,0.359,0.473,0.52,0.719c0.285,0.562,0.566,1.117,0.855,1.672c0.285,0.484,0.684,0.918,0.98,1.402
			c0.398,0.66,0.797,1.316,1.191,1.969c0.137,0.199,0.344,0.355,0.469,0.547c0.371,0.562,0.586,1.277,1.211,1.66
			c0.172,0.102,0.402,0.23,0.621,0.215c0.262-0.02,0.445-0.195,0.645-0.324c0.141-0.078,0.281-0.156,0.426-0.238
			c0.555-0.418,1.508-1.359,2.211-1.359c0.082-0.016,0.156-0.027,0.227-0.047c1.141-0.367,2.328,1.465,2.098,2.402
			c-0.059,0.238-0.289,0.477-0.477,0.652c-0.691,0.672-1.488,1.055-2.227,1.609c-0.227,0.164-0.516,0.34-0.641,0.594
			c-0.062,0.117-0.086,0.27-0.055,0.414c0.062,0.332,0.312,0.684,0.461,0.973c0.301,0.598,0.602,1.188,0.91,1.781
			c0.191,0.258,0.387,0.512,0.578,0.77c0.297,0.445,0.59,0.895,0.887,1.34c0.273,0.375,0.547,0.754,0.828,1.129
			c0.719,1.043,1.176,2.223,1.871,3.266c0.141,0.148,0.277,0.297,0.414,0.441c0.125,0.199,0.254,0.398,0.383,0.598
			c0.113,0.137,0.328,0.328,0.527,0.25c0.152-0.066,0.234-0.297,0.305-0.422c0.133-0.301,0.27-0.594,0.406-0.891
			c0.086-0.449,0.172-0.898,0.262-1.34c0.234-0.969,0.355-1.945,0.473-2.965c0.008-0.57,0.016-1.148,0.023-1.719
			c0.051-0.648,0.215-1.273,0.203-1.949c-0.008-0.5-0.16-0.848-0.312-1.262c-0.105-0.281-0.016-0.633-0.082-0.938
			c-0.043-0.227-0.18-0.375-0.25-0.582c-0.184-0.527-0.312-1.059-0.535-1.562c-0.445-0.977-1.086-1.863-1.641-2.797
			c-0.062-0.148-0.129-0.301-0.195-0.453c-0.031-0.113-0.07-0.23-0.105-0.352c-0.105-0.094-0.211-0.195-0.32-0.293
			c-0.172-0.191-0.344-0.375-0.516-0.559c-0.41-0.523-0.82-1.047-1.23-1.566c-0.266-0.32-0.613-0.547-0.891-0.84
			c-0.203-0.246-0.406-0.488-0.609-0.738c-0.578-0.59-1.234-1.066-1.883-1.527c-0.254-0.199-0.512-0.395-0.766-0.594
			c-1.219-0.406-2.434-0.805-3.645-1.211c-0.168-0.043-0.301-0.172-0.441-0.262c-0.172-0.086-0.348-0.168-0.52-0.254
			C172.309,12.065,171.137,11.89,170.059,11.854 M165.125,5.229c0.32,0.004,0.648,0.008,0.973,0.012
			c0.441,0.062,0.855,0.23,1.32,0.273c0.672,0.047,1.449-0.008,2.094,0.141c0.18,0.059,0.355,0.117,0.531,0.18
			c0.281,0.066,0.566,0.129,0.852,0.203c0.203,0.074,0.398,0.152,0.598,0.227c0.641,0.176,1.367,0.137,1.984,0.348
			c0.461,0.152,0.773,0.547,1.227,0.715c0.672,0.254,1.496,0.238,2.152,0.523c0.281,0.156,0.555,0.305,0.84,0.453
			c0.371,0.156,0.75,0.316,1.121,0.473c0.312,0.172,0.57,0.441,0.875,0.621c0.512,0.297,1.051,0.523,1.535,0.875
			c0.465,0.336,0.754,0.812,1.164,1.215c0.41,0.375,0.82,0.75,1.23,1.125c0.391,0.43,0.699,0.891,1.09,1.328
			c0.594,0.684,1.227,1.297,1.727,2.012c0.355,0.516,0.516,1.109,0.848,1.633c0.328,0.516,0.793,0.977,1.164,1.473
			c0.156,0.203,0.23,0.434,0.363,0.648c0.188,0.316,0.43,0.602,0.602,0.918c0.301,0.605,0.602,1.203,0.898,1.801
			c0.289,0.555,0.645,1.082,0.844,1.688c0.367,1.117,0.461,2.32,0.586,3.574c0.047,0.469,0.203,0.914,0.195,1.434
			c-0.008,0.465-0.078,0.902-0.168,1.324c-0.043,0.188-0.086,0.371-0.133,0.559c0.012,0.227,0.016,0.453,0.023,0.684
			c-0.062,0.602-0.129,1.203-0.195,1.812c-0.004,0.234-0.012,0.465-0.016,0.703c-0.043,0.125-0.078,0.254-0.117,0.379
			c-0.07,0.277-0.125,0.559-0.156,0.859c-0.086,0.691-0.035,1.43-0.227,2.074c-0.16,0.57-0.574,0.988-0.805,1.527
			c-0.129,0.355-0.258,0.715-0.383,1.07c-0.133,0.355-0.348,0.652-0.531,0.977c-0.125,0.277-0.254,0.551-0.383,0.824
			c-0.066,0.113-0.156,0.242-0.27,0.336c-0.094,0.082-0.191,0.164-0.281,0.25c-0.066,0.18-0.125,0.355-0.18,0.535
			c-0.148,0.352-0.352,0.656-0.578,0.973c-0.512,0.738-1.293,1.375-1.906,2.051c-0.246,0.27-0.457,0.559-0.645,0.859
			c-0.109,0.188-0.219,0.375-0.328,0.562c-0.531,0.426-1.07,0.855-1.602,1.273c-0.211,0.117-0.418,0.234-0.625,0.344
			c-0.445,0.336-0.648,0.812-1.055,1.16c-0.672,0.574-1.516,0.781-2.238,1.234c-0.434,0.27-0.824,0.699-1.301,0.902
			c-0.82,0.352-1.809,0.41-2.703,0.629c-0.227,0.074-0.445,0.152-0.672,0.23c-0.152,0.062-0.293,0.133-0.445,0.195
			c-0.34,0.059-0.691-0.031-1.043-0.004c-0.473,0.035-0.887,0.223-1.324,0.328c-0.277,0.059-0.547,0.113-0.82,0.164
			c-0.188,0.02-0.371,0.043-0.566,0.066c-0.148,0.066-0.301,0.137-0.453,0.211c-0.238,0.082-0.5,0.102-0.723,0.188
			c-0.949,0.336-1.938,0.832-3.016,0.867c-0.723,0.02-1.375-0.066-1.969-0.277c-0.434-0.156-0.824-0.375-1.309-0.422
			c-0.461,0-0.922-0.008-1.387-0.012c-0.164-0.031-0.336-0.059-0.5-0.094c-1.012-0.113-1.973-0.137-2.91-0.355
			c-0.27-0.059-0.496-0.195-0.738-0.305c-0.363-0.16-0.734-0.289-1.109-0.402c-0.938-0.289-1.922-0.508-2.816-0.859
			c-0.516-0.207-0.977-0.492-1.465-0.734c-1.137-0.562-2.246-1.082-3.316-1.781c-0.547-0.359-1.035-0.824-1.562-1.215
			c-0.285-0.184-0.562-0.363-0.852-0.547c-0.887-0.602-1.746-1.336-2.523-2.145c-0.738-0.754-1.141-1.668-1.812-2.457
			c-0.562-0.652-1.172-1.27-1.66-1.949c-0.215-0.309-0.32-0.652-0.508-0.965c-0.156-0.258-0.383-0.473-0.512-0.738
			c-0.277-0.574-0.387-1.227-0.652-1.805c-0.137-0.289-0.352-0.52-0.496-0.809c-0.051-0.188-0.105-0.375-0.152-0.562
			c-0.145-0.449-0.344-0.875-0.422-1.355c-0.016-0.367-0.035-0.73-0.051-1.102c0.012-0.188,0.02-0.379,0.027-0.57
			c-0.031-0.387-0.195-0.766-0.23-1.176c-0.047-0.508,0.02-1.004,0.035-1.504c0.012-0.422,0.023-0.848,0.035-1.273
			c0.105-0.516,0.207-1.031,0.309-1.551c0.055-0.16,0.113-0.328,0.168-0.488c0.008-0.25,0.016-0.516,0.023-0.77
			c0.027-0.379,0.105-0.734,0.207-1.078c0.191-0.66,0.473-1.273,0.707-1.906c0.094-0.281,0.191-0.555,0.281-0.836
			c0.094-0.223,0.27-0.398,0.34-0.641c0.105-0.328,0.113-0.727,0.148-1.078c0.031-0.133,0.055-0.266,0.078-0.402
			c0.012-0.211,0.023-0.422,0.035-0.641c0.109-0.578,0.461-1.066,0.641-1.625c0.117-0.379-0.02-0.715-0.039-1.066
			c-0.043-0.562,0.156-1.176,0.43-1.602c0.145-0.23,0.367-0.379,0.566-0.555c0.082-0.113,0.168-0.234,0.246-0.352
			c0.156-0.117,0.309-0.238,0.461-0.355c0.301-0.23,0.535-0.438,0.871-0.582c0.535-0.246,1.148-0.332,1.594-0.734
			c0.328-0.305,0.543-0.668,0.902-0.957c0.246-0.207,0.5-0.402,0.789-0.543c0.207-0.098,0.414-0.199,0.625-0.301
			c0.328-0.285,0.648-0.57,0.973-0.855c0.68-0.512,1.375-0.984,2.102-1.41c0.238-0.145,0.527-0.414,0.832-0.414
			c0.355-0.184,0.766-0.277,1.129-0.477c0.68-0.363,1.316-0.848,2.02-1.164c0.273-0.129,0.574-0.199,0.875-0.281
			c0.465-0.156,0.93-0.305,1.395-0.461c0.48-0.168,0.887-0.551,1.457-0.551c0.129-0.074,0.336-0.113,0.5-0.113
			c0.16-0.066,0.383-0.109,0.574-0.109c0.062-0.012,0.133-0.027,0.199-0.039c0.121-0.023,0.242-0.047,0.363-0.07
			c0.102-0.02,0.199-0.047,0.297-0.066c0.066-0.012,0.141-0.027,0.203-0.039c0.277-0.074,0.547-0.152,0.82-0.227
			c0.125-0.055,0.285-0.066,0.438-0.066c0.113-0.039,0.242-0.043,0.387-0.043c0.105-0.008,0.207-0.012,0.316-0.023
			c0.07-0.004,0.141-0.012,0.203-0.02c0.141-0.008,0.273-0.016,0.406-0.023c0.062-0.008,0.105-0.012,0.164-0.027
			c0.176-0.043,0.578,0.156,0.777,0.191c0.211,0.004,0.422,0.012,0.637,0.012c0.051-0.008,0.105-0.012,0.156-0.02
			c0.09-0.016,0.18-0.031,0.273-0.047c0.074-0.016,0.148-0.027,0.227-0.043c0.156-0.074,0.402-0.109,0.59-0.109
			c0.051-0.008,0.094-0.02,0.141-0.023C165.075,5.257,165.098,5.245,165.125,5.229"
					/>
					<path
						d="M154.047,68.675c2.535-3.641,10.199-7.547,17.754-7.547c7.559,0,15.723,4.23,18.977,1.977
			c0,0-3.309,2.789-9.984,2.789c-6.672,0-9.211-3.27-13.844-3.27c-4.629,0-9.539,3.641-11.582,6.688L154.047,68.675z"
					/>
					<path
						d="M177.872,60.593c0,0,2.203-1.285,3.363-3.637s4.191-6.102,7.223-8.398c0,0-3.418,10.484-6.953,10.965
			c0,0-0.543,0.324,0.445,0.164c0.996-0.164,5.52-1.762,7.117-2.035c1.598-0.27,5.516-1.125,6.562-2.137
			c0.879-0.859,1.488-0.746,1.488-0.746s-4.355,7.055-12.184,7.055C184.934,61.823,181.071,61.503,177.872,60.593"
					/>
					<path
						d="M187.133,56.956c0,0,0.832-1.23,0.996-3.316c0.16-2.082,1.488-6.898,5.238-12.145
			c0,0,0.551,8.293-3.695,13.379c0,0-0.273,0.746,0.273,0.211c0.551-0.535,3.258-3.691,9.047-7.492c0,0,3.039-2.41,3.195-3.207
			C202.188,44.386,199.821,56.155,187.133,56.956"
					/>
					<path
						d="M194.469,49.093c0,0,0.336-1.336,0.336-2.461s-0.5-4.977-0.5-6.684c0-1.719,0.164-4.926,1.547-7.121
			c0,0,0,1.93,0.441,2.785c0.438,0.852,0.934,4.277,0.934,4.707c0,0.426-0.105,3.316-0.883,4.867c0,0,0.551-0.32,1.164-1.445
			c0.605-1.121,2.09-3.211,4.352-5.402c2.266-2.195,2.762-4.227,2.762-4.227s0.551,3.852-2.379,7.918
			c-2.918,4.07-4.129,3.367-5.73,5.027C196.512,47.058,194.692,49.198,194.469,49.093"
					/>
					<path
						d="M199.047,39.839c0,0-0.113-2.359-0.551-3.105c-0.445-0.75-3.09-5.406-2.535-11.34
			c0,0,0.441-0.375,1.262,1.922c0.828,2.305,1.988,2.996,2.371,5.297c0.395,2.305,0.395,3.266,0.395,3.266s0.27,0.32,0.27,0.055
			c0-0.27,0.281-3.371,1.602-6.203c1.328-2.84,3.09-5.512,2.922-7.383c0,0,0.773,0.051,0.664,3.098
			C205.336,28.495,204.508,33.788,199.047,39.839"
					/>
					<path
						d="M200.485,30.476c0,0-0.938-2.141-1.598-2.836c-0.664-0.699-5.238-7.168-5.629-10.113
			c0,0,0.391-0.211,0.824,0.535c0.445,0.75,2.648,2.895,3.035,3.211c0.391,0.32,2.707,2.73,2.926,5.297c0,0,0.219,0.27-0.219-4.012
			c-0.445-4.281,1.43-7.059-0.113-10.055c0,0,2.148,0.32,3.309,9.09c0,0-0.059,3.105-1.711,6.906
			C201.309,28.499,200.758,30.476,200.485,30.476"
					/>
					<path
						d="M198.829,20.847c0,0-1.711-1.711-3.09-2.516s-5.68-4.922-6.449-8.133c0,0,0.281-0.355,1.652,1.391
			c1.051,1.336,2.152,0.699,5.297,4.551c0,0,0.441,0.855,0.66,0.805c0.219-0.059-0.113-0.914-0.113-0.914s-0.605-2.406-0.66-3.266
			c-0.055-0.855-1.32-5.402-1.766-5.988c0,0,4.25,2.355,4.852,8.984C199.211,15.761,199.047,20.573,198.829,20.847"
					/>
					<path
						d="M195.188,13.784c0,0,0.277-5.781-7.559-8.723c0,0,2.703,5.406,4.746,6.691
			C194.418,13.034,195.188,13.784,195.188,13.784"
					/>
					<path
						d="M175.11,68.675c-2.535-3.641-10.199-7.547-17.75-7.547c-7.559,0-15.723,4.23-18.973,1.977
			c0,0,3.309,2.789,9.98,2.789s9.207-3.27,13.84-3.27c4.637,0,9.535,3.641,11.582,6.688L175.11,68.675z"
					/>
					<path
						d="M151.29,60.593c0,0-2.203-1.285-3.363-3.637s-4.191-6.102-7.227-8.398c0,0,3.418,10.484,6.953,10.965
			c0,0,0.547,0.324-0.445,0.164c-0.996-0.164-5.516-1.762-7.113-2.035c-1.598-0.27-5.512-1.125-6.562-2.137
			c-0.883-0.859-1.492-0.746-1.492-0.746s4.363,7.055,12.191,7.055C144.231,61.823,148.094,61.503,151.29,60.593"
					/>
					<path
						d="M142.024,56.956c0,0-0.828-1.23-0.992-3.316c-0.164-2.082-1.488-6.898-5.238-12.145
			c0,0-0.555,8.293,3.695,13.379c0,0,0.277,0.746-0.277,0.211c-0.551-0.535-3.258-3.691-9.043-7.492c0,0-3.039-2.41-3.199-3.207
			C126.969,44.386,129.34,56.155,142.024,56.956"
					/>
					<path
						d="M134.688,49.093c0,0-0.328-1.336-0.328-2.461s0.492-4.977,0.492-6.684c0-1.719-0.164-4.926-1.539-7.121
			c0,0,0,1.93-0.445,2.785c-0.438,0.852-0.934,4.277-0.934,4.707c0,0.426,0.109,3.316,0.879,4.867c0,0-0.551-0.32-1.156-1.445
			c-0.605-1.121-2.102-3.211-4.359-5.402c-2.262-2.195-2.758-4.227-2.758-4.227s-0.551,3.852,2.375,7.918
			c2.922,4.07,4.137,3.367,5.734,5.027C132.649,47.058,134.469,49.198,134.688,49.093"
					/>
					<path
						d="M130.114,39.839c0,0,0.105-2.359,0.547-3.105c0.445-0.75,3.09-5.406,2.539-11.34
			c0,0-0.441-0.375-1.266,1.922c-0.828,2.305-1.984,2.996-2.371,5.297c-0.387,2.305-0.387,3.266-0.387,3.266
			s-0.277,0.32-0.277,0.055c0-0.27-0.273-3.371-1.602-6.203c-1.32-2.84-3.09-5.512-2.922-7.383c0,0-0.77,0.051-0.664,3.098
			C123.821,28.495,124.653,33.788,130.114,39.839"
					/>
					<path
						d="M128.68,30.476c0,0,0.934-2.141,1.598-2.836c0.66-0.699,5.238-7.168,5.621-10.113
			c0,0-0.383-0.211-0.82,0.535c-0.445,0.75-2.648,2.895-3.035,3.211c-0.387,0.32-2.703,2.73-2.922,5.297c0,0-0.227,0.27,0.219-4.012
			c0.441-4.281-1.438-7.059,0.109-10.055c0,0-2.152,0.32-3.309,9.09c0,0,0.055,3.105,1.711,6.906
			C127.852,28.499,128.403,30.476,128.68,30.476"
					/>
					<path
						d="M130.333,20.847c0,0,1.711-1.711,3.086-2.516c1.383-0.805,5.684-4.922,6.457-8.133
			c0,0-0.289-0.355-1.656,1.391c-1.047,1.336-2.152,0.699-5.297,4.551c0,0-0.438,0.855-0.66,0.805
			c-0.219-0.059,0.109-0.914,0.109-0.914s0.613-2.406,0.664-3.266c0.055-0.855,1.324-5.402,1.766-5.988c0,0-4.25,2.355-4.855,8.984
			C129.946,15.761,130.114,20.573,130.333,20.847"
					/>
					<path
						d="M133.969,13.784c0,0-0.273-5.781,7.559-8.723c0,0-2.707,5.406-4.742,6.691
			C134.747,13.034,133.969,13.784,133.969,13.784"
					/>
					<path
						d="M4.782,4.948h12.461V39.39c0,10.168,3.379,16.836,12.898,16.836c9.523,0,12.906-6.668,12.906-16.836V4.948
			H55.5v38.117c0,15.98-9.426,22.559-25.359,22.559c-15.926,0-25.359-6.578-25.359-22.559V4.948z"
					/>
				</g>
				<polygon
					points="64.403,4.854 79.711,4.854 103.379,50.362 103.559,50.362 103.559,4.854 115.489,4.854
		115.489,65.71 100.266,65.71 76.508,20.198 76.329,20.198 76.329,65.71 64.403,65.71 	"
				/>
				<polygon
					points="213.504,4.89 225.961,4.89 225.961,29.476 250.883,29.476 250.883,4.89 263.336,4.89
		263.336,65.745 250.883,65.745 250.883,39.065 225.961,39.065 225.961,65.745 213.504,65.745 	"
				/>
				<path
					d="M299.915,17.093h-0.176l-8.988,25.113h18.152L299.915,17.093z M293.063,4.89h14.058l24.383,60.855h-13.789
		l-5.34-13.953h-25.183l-5.43,13.953h-12.906L293.063,4.89z"
				/>
				<g>
					<path
						d="M350.191,56.155h5.875c5.785,0,12.816-1.398,12.816-8.457c0-7.938-6.586-9.156-12.816-9.156h-5.875V56.155
			z M350.191,29.476h5.074c5.25,0,11.746-1.133,11.746-7.41c0-7.062-6.316-7.582-12.012-7.582h-4.809V29.476z M337.734,4.89h21.711
			c8.008,0,20.027,2.27,20.027,15.605c0,7.234-4.988,12.293-12.375,13.602v0.172c8.453,0.785,14.242,6.371,14.242,13.863
			c0,15.348-14.062,17.613-21.895,17.613h-21.711V4.89z"
					/>
				</g>
				<rect x="389.262" y="4.89" width="12.457" height="60.855" />
				<polygon
					points="426.371,14.479 408.57,14.479 408.57,4.89 456.622,4.89 456.622,14.479 438.824,14.479
		438.824,65.745 426.371,65.745 	"
				/>
				<path
					d="M480.302,17.093h-0.18l-8.992,25.113h18.156L480.302,17.093z M473.446,4.89h14.062l24.383,60.855h-13.793
		l-5.344-13.953h-25.184l-5.426,13.953h-12.906L473.446,4.89z"
				/>
				<polygon
					points="521.856,14.479 504.06,14.479 504.06,4.89 552.114,4.89 552.114,14.479 534.313,14.479
		534.313,65.745 521.856,65.745 	"
				/>
				<polygon
					points="3.961,75.011 18.395,75.011 18.395,78.437 7.848,78.437 7.848,86.374 17.926,86.374 17.926,89.792
		7.848,89.792 7.848,102.167 3.961,102.167 	"
				/>
				<g>
					<path
						d="M35.317,99.214c6.262,0,8.945-5.215,8.945-10.625c0-5.523-2.957-10.66-8.945-10.621
			c-5.992-0.039-8.949,5.098-8.949,10.621C26.368,93.999,29.051,99.214,35.317,99.214 M35.317,74.546
			c8.246,0,13.066,5.992,13.066,14.043c0,8.25-4.785,14.043-13.066,14.043c-8.289,0-13.074-5.793-13.074-14.043
			C22.243,80.538,27.067,74.546,35.317,74.546"
					/>
					<path
						d="M57.563,86.679h3.309c3.695,0,5.875-1.625,5.875-4.234c0-2.453-1.867-4.008-5.445-4.008h-3.738V86.679z
			 M53.676,75.011h6.656c5.363,0,10.539,0.859,10.539,7.359c0,3.301-2.453,5.637-5.598,6.184v0.074
			c1.203,0.469,1.785,1.047,2.641,2.723l5.137,10.816h-4.438l-4.16-9.414c-1.211-2.645-2.488-2.645-4.555-2.645h-2.336v12.059
			h-3.887V75.011z"
					/>
				</g>
				<path
					d="M98.993,78.667L93.59,92.128h10.816L98.993,78.667z M97.09,75.011h4.086l11.477,27.156h-4.242l-2.762-6.848
		H92.344l-2.762,6.848h-4.086L97.09,75.011z"
				/>
				<g>
					<path
						d="M131.047,98.976h2.922c3.5,0,6.652-0.734,6.652-4.395c0-4.199-3.695-4.789-7.039-4.789h-2.535V98.976z
			 M131.047,86.604h2.141c3.777,0,6.965-0.348,6.965-4.355c0-3.23-2.758-4.043-5.016-4.043h-4.09V86.604z M127.161,75.011h7.352
			c5.641,0,9.531,1.828,9.531,6.848c0,3.734-2.18,5.602-5.598,6.457v0.082c3.691,0.312,6.301,2.723,6.301,6.535
			c0,5.832-4.824,7.234-10.348,7.234h-7.238V75.011z"
					/>
				</g>
				<polygon
					points="151.36,75.011 166.571,75.011 166.571,78.437 155.247,78.437 155.247,86.374 165.559,86.374
		165.559,89.792 155.247,89.792 155.247,98.745 166.571,98.745 166.571,102.167 151.36,102.167 	"
				/>
				<polygon
					points="178.2,78.437 169.758,78.437 169.758,75.015 190.532,75.015 190.532,78.437 182.09,78.437
		182.09,102.171 178.2,102.171 	"
				/>
				<polygon
					points="199.829,78.437 191.391,78.437 191.391,75.015 212.161,75.015 212.161,78.437 203.723,78.437
		203.723,102.171 199.829,102.171 	"
				/>
				<polygon
					points="216.247,75.011 231.458,75.011 231.458,78.437 220.137,78.437 220.137,86.374 230.446,86.374
		230.446,89.792 220.137,89.792 220.137,98.745 231.458,98.745 231.458,102.167 216.247,102.167 	"
				/>
				<g>
					<path
						d="M241.34,86.679h3.305c3.695,0,5.875-1.625,5.875-4.234c0-2.453-1.867-4.008-5.445-4.008h-3.734V86.679z
			 M237.45,75.011h6.652c5.367,0,10.543,0.859,10.543,7.359c0,3.301-2.453,5.637-5.602,6.184v0.074
			c1.203,0.469,1.789,1.047,2.645,2.723l5.137,10.816h-4.441l-4.16-9.414c-1.203-2.645-2.488-2.645-4.551-2.645h-2.332v12.059
			h-3.891V75.011z"
					/>
					<path
						d="M293.391,90.925c0,7.625-3.852,11.707-10.547,11.707c-6.691,0-10.543-4.082-10.543-11.707V75.011h3.891
			v15.406c0,5.219,1.633,8.797,6.652,8.797s6.656-3.578,6.656-8.797V75.011h3.891V90.925z"
					/>
					<path
						d="M304.008,86.679h3.305c3.695,0,5.875-1.625,5.875-4.234c0-2.453-1.863-4.008-5.445-4.008h-3.734V86.679z
			 M300.118,75.011h6.652c5.367,0,10.539,0.859,10.539,7.359c0,3.301-2.445,5.637-5.598,6.184v0.074
			c1.203,0.469,1.793,1.047,2.645,2.723l5.141,10.816h-4.441l-4.16-9.414c-1.207-2.645-2.488-2.645-4.551-2.645h-2.336v12.059h-3.89
			V75.011z"
					/>
					<path
						d="M327.777,98.976h2.918c3.5,0,6.652-0.734,6.652-4.395c0-4.199-3.699-4.789-7.043-4.789h-2.527V98.976z
			 M327.777,86.604h2.137c3.777,0,6.969-0.348,6.969-4.355c0-3.23-2.766-4.043-5.02-4.043h-4.086V86.604z M323.887,75.011h7.352
			c5.645,0,9.531,1.828,9.531,6.848c0,3.734-2.18,5.602-5.598,6.457v0.082c3.695,0.312,6.297,2.723,6.297,6.535
			c0,5.832-4.824,7.234-10.348,7.234h-7.234V75.011z"
					/>
				</g>
				<path
					d="M358.391,78.667l-5.406,13.461h10.816L358.391,78.667z M356.484,75.011h4.086l11.477,27.156h-4.242
		l-2.758-6.848h-13.305l-2.766,6.848h-4.082L356.484,75.011z"
				/>
				<polygon
					points="375.938,75.011 381.227,75.011 393.207,96.874 393.285,96.874 393.285,75.011 397.176,75.011
		397.176,102.167 392.195,102.167 379.902,79.835 379.824,79.835 379.824,102.167 375.938,102.167 	"
				/>
				<polygon
					points="414.645,75.011 429.078,75.011 429.078,78.437 418.535,78.437 418.535,86.374 428.609,86.374
		428.609,89.792 418.535,89.792 418.535,102.167 414.645,102.167 	"
				/>
				<g>
					<path
						d="M455.45,90.925c0,7.625-3.852,11.707-10.539,11.707c-6.692,0-10.544-4.082-10.544-11.707V75.011h3.891
			v15.406c0,5.219,1.633,8.797,6.653,8.797c5.016,0,6.652-3.578,6.652-8.797V75.011h3.887V90.925z"
					/>
				</g>
				<polygon
					points="467.821,78.437 459.38,78.437 459.38,75.015 480.153,75.015 480.153,78.437 471.716,78.437
		471.716,102.171 467.821,102.171 	"
				/>
				<g>
					<path
						d="M505.165,90.925c0,7.625-3.848,11.707-10.539,11.707c-6.695,0-10.539-4.082-10.539-11.707V75.011h3.887
			v15.406c0,5.219,1.633,8.797,6.652,8.797s6.652-3.578,6.652-8.797V75.011h3.887V90.925z"
					/>
					<path
						d="M515.79,86.679h3.305c3.695,0,5.875-1.625,5.875-4.234c0-2.453-1.867-4.008-5.449-4.008h-3.73V86.679z
			 M511.899,75.011h6.648c5.375,0,10.547,0.859,10.547,7.359c0,3.301-2.449,5.637-5.605,6.184v0.074
			c1.207,0.469,1.793,1.047,2.645,2.723l5.137,10.816h-4.434l-4.164-9.414c-1.207-2.645-2.488-2.645-4.551-2.645h-2.332v12.059
			h-3.891V75.011z"
					/>
				</g>
				<polygon
					points="536.091,75.011 551.306,75.011 551.306,78.437 539.985,78.437 539.985,86.374 550.294,86.374
		550.294,89.792 539.985,89.792 539.985,98.745 551.306,98.745 551.306,102.167 536.091,102.167 	"
				/>
			</g>
		</svg>
	</div>
);

export default Logo;
