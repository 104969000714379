import PropTypes from 'prop-types';
import {Divider, VisatLogo} from './components';
import AppSelect from './AppSelect';

import './style.scss';

const XCubeHeader = ({visatUrl}) => {
	return (
		<div className="xCubeApp-header">
			<VisatLogo visatUrl={visatUrl} />
			<Divider />
			<AppSelect />
		</div>
	);
};

XCubeHeader.propTypes = {
	visatUrl: PropTypes.string,
};

export default XCubeHeader;
