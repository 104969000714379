import Map from './Map';
import AppContent from '../../common/AppContent';
import Header from '../../common/Header';
import XCubePanel from './XCubePanel';

import './style.scss';
import {useEffect} from 'react';
import PropTypes from 'prop-types';

const XCube = ({onMount, pointAddingEnabled}) => {
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);
	return (
		<>
			<Header />
			<AppContent>
				<Map pointAddingEnabled={pointAddingEnabled} />
				<XCubePanel />
			</AppContent>
		</>
	);
};

XCube.propTypes = {
	onMount: PropTypes.func,
	pointAddingEnabled: PropTypes.bool,
};

export default XCube;
