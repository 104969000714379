import PropTypes from 'prop-types';
import Select from '../../../Select';
import './style.scss';
import LayerSettingsItem from '../SortLayersControl/LayerSettings/LayerSettingsItem';

const XCubeTimeSelect = ({activeTimeKey, onTimeChange, times}) => {
	const models = times.map(time => {
		// TODO move to BE after API settle up
		const finalTime = time?.split('/')[0]?.split('.')[0];
		const displayTimes = finalTime.split('T');
		return {
			key: finalTime,
			data: {nameDisplay: `${displayTimes?.[0]} (${displayTimes?.[1]})`},
		};
	});
	return (
		<LayerSettingsItem title="Date" key="date">
			<div className="unhab-XCubeTimeSelect">
				<Select
					models={models}
					small
					activeKey={activeTimeKey || models[models.length - 1].key}
					onChange={onTimeChange}
					portaling
					lightweight
				/>
			</div>
		</LayerSettingsItem>
	);
};

XCubeTimeSelect.propTypes = {
	activeTimeKey: PropTypes.string,
	onTimeChange: PropTypes.func,
	times: PropTypes.array,
};

const XCubeSortLayersControl = ({layer, onLayerTimeChange, activeTimeKey}) => {
	const onTimeChange = ({value}) => {
		if (value) {
			onLayerTimeChange(value);
		}
	};
	return (
		<XCubeTimeSelect
			times={layer.times}
			onTimeChange={onTimeChange}
			activeTimeKey={activeTimeKey}
		/>
	);
};

XCubeSortLayersControl.propTypes = {
	onLayerTimeChange: PropTypes.func,
	layer: PropTypes.object,
	activeTimeKey: PropTypes.string,
};

export default XCubeSortLayersControl;
