import {createSelector} from 'reselect';
import {forIn as _forIn} from 'lodash';
import {featuredCountryKeys} from '../../../constants/app';

const getSubstate = state => state.unhab.admStructure;

const getAdmStructure = createSelector([getSubstate], substate => {
	return substate.structure;
});

const getCountries = createSelector(
	[getAdmStructure, () => featuredCountryKeys],
	(structure, highlightedCountryKeys) => {
		if (structure) {
			const finalStructure = {};
			_forIn(structure, (data, key) => {
				if (highlightedCountryKeys.includes(data.key)) {
					finalStructure[key] = {
						...data,
						data: {
							...data.data,
							featured: true,
						},
					};
				} else {
					finalStructure[key] = data;
				}
			});

			return finalStructure;
		} else {
			return {};
		}
	},
);

const getFeaturedCountries = createSelector(
	[getAdmStructure, () => featuredCountryKeys],
	(structure, highlightedCountryKeys) => {
		if (structure) {
			const finalStructure = {};
			_forIn(structure, (data, key) => {
				if (highlightedCountryKeys.includes(data.key)) {
					finalStructure[key] = {
						...data,
						data: {
							...data.data,
						},
					};
				}
			});

			return finalStructure;
		} else {
			return {};
		}
	},
);

const getAdmByKey = createSelector(
	[getSubstate, (state, key) => key],
	(substate, key) => {
		const structure = substate.structure;
		if (Object.hasOwn(structure, key)) {
			return structure[key];
		} else {
			const adm0code = key.split('_')[0];
			return structure[adm0code]?.regionsByFeatureKey?.[key];
		}
	},
);

export default {
	getAdmStructure,
	getAdmByKey,
	getCountries,
	getFeaturedCountries,
};
