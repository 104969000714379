import moment from 'moment';
import {DAY_IN_SECONDS} from './constants';

/**
 * Get date format for axis X based on time range
 * @param startDate {String} ISO 8601 date
 * @param endDate {String} ISO 8601 date
 * @param momentTimeFormat {String} time format using by moment js library
 * @returns {string} Format accepted by nivo charts
 */
const getDateFormatBasedOnTimeRangeForAxisX = (
	startDate,
	endDate,
	momentTimeFormat,
) => {
	if (startDate && endDate && !momentTimeFormat) {
		const startSecond = moment(startDate).unix();
		const endSecond = moment(endDate).unix();
		const days = (endSecond - startSecond) / DAY_IN_SECONDS;
		if (days > 1200) {
			return '%Y';
		} else if (days > 400) {
			return '%b %Y';
		} else {
			return '%d %b %Y';
		}
	} else if (momentTimeFormat) {
		let format = '%Y';
		if (momentTimeFormat === 'D MMMM') {
			format = '%e %B';
		}

		return format;
	} else {
		return '%Y';
	}
};

/**
 * Get time range from input data
 * @param data {Array} following nivo line chart input data format (https://nivo.rocks/line/). Assumed to be sorted chronologically
 * @returns {{endDate: string || null, startDate: string || null}} Dates in ISO format
 */
const getTimeRangeFromData = data => {
	let startDate = null,
		endDate = null;
	if (data) {
		data.forEach(line => {
			const firstDate = line.data?.[0]?.x;
			const lastDate = line.data?.[line.data?.length - 1]?.x;
			if (
				!startDate ||
				(firstDate && startDate && moment(firstDate).isBefore(startDate))
			) {
				startDate = firstDate;
			}

			if (
				!endDate ||
				(lastDate && endDate && moment(lastDate).isAfter(endDate))
			) {
				endDate = lastDate;
			}
		});
	}

	return {
		startDate,
		endDate,
	};
};

const getInterpolatedPointInTimeSerie = (prevPoint, nextPoint, date) => {
	const prevDateMs = moment(prevPoint.x).unix();
	const dataMs = moment(date).unix();
	const nextDateMs = moment(nextPoint.x).unix();
	const prevValue = prevPoint.y;
	const nextValue = nextPoint.y;

	const a = (nextValue - prevValue) / (nextDateMs - prevDateMs);
	const b = nextValue - a * nextDateMs;
	const value = a * dataMs + b;

	return {
		x: date,
		y: value,
		noHover: true,
	};
};

/**
 * Get time range from input data
 * @param data {Array} following nivo line chart input data format (https://nivo.rocks/line/). Assumed to be sorted chronologically
 * @param startDate {String} ISO 8601 date
 * @param endDate {String} ISO 8601 date
 * @param extendLines {boolean} If true, compute the points on the edges to extend lines
 * @returns {Array}
 */
const getDataByTimeRange = (data, startDate, endDate, extendLines) => {
	if (data) {
		if (startDate && endDate) {
			return data.map(item => {
				// original time serie
				const fullTimeSeries = item.data;

				let lastPointBeforeSelectionIndex = null;
				let firstPointAfterSelectionIndex = null;

				// select the points that correspond to the time range
				const selectedTimeSeries = fullTimeSeries.filter((point, i) => {
					const startIsBeforePointDate =
						moment(startDate).isBefore(point.x) ||
						moment(startDate).isSame(point.x);
					const endIsAfterPointDate =
						moment(endDate).isAfter(point.x) || moment(endDate).isSame(point.x);

					// store indexes of specific points for line extending
					if (extendLines) {
						if (!startIsBeforePointDate && endIsAfterPointDate) {
							lastPointBeforeSelectionIndex = i;
						}
						if (
							!endIsAfterPointDate &&
							startIsBeforePointDate &&
							!firstPointAfterSelectionIndex
						) {
							firstPointAfterSelectionIndex = i;
						}
					}

					// filter points fitting the time range
					return startIsBeforePointDate && endIsAfterPointDate;
				});

				// add additional helper points for line extension
				if (extendLines) {
					if (
						lastPointBeforeSelectionIndex !== null &&
						lastPointBeforeSelectionIndex >= 0
					) {
						const previousPoint = fullTimeSeries[lastPointBeforeSelectionIndex];
						const firstPoint =
							selectedTimeSeries[0] ||
							fullTimeSeries[firstPointAfterSelectionIndex];
						if (previousPoint && firstPoint) {
							selectedTimeSeries.unshift(
								getInterpolatedPointInTimeSerie(
									previousPoint,
									firstPoint,
									startDate,
								),
							);
						}
					}

					if (
						firstPointAfterSelectionIndex &&
						firstPointAfterSelectionIndex < fullTimeSeries?.length
					) {
						const nextPoint = fullTimeSeries[firstPointAfterSelectionIndex];
						const lastPoint =
							selectedTimeSeries[selectedTimeSeries.length - 1] ||
							fullTimeSeries[lastPointBeforeSelectionIndex];

						if (lastPoint && nextPoint) {
							selectedTimeSeries.push(
								getInterpolatedPointInTimeSerie(lastPoint, nextPoint, endDate),
							);
						}
					}
				}

				return {
					...item,
					data: selectedTimeSeries,
				};
			});
		} else {
			return data;
		}
	} else {
		return null;
	}
};

export default {
	getDateFormatBasedOnTimeRangeForAxisX,
	getTimeRangeFromData,
	getDataByTimeRange,
};
