import PropTypes from 'prop-types';
import './style.scss';

const TimeProgressChartLegendItem = ({id, color, name, showFeatureKey}) => {
	const title = name ? (showFeatureKey ? `${name} (${id})` : name) : id;
	return (
		<div className="unhab-TimeProgressChartLegendItem">
			<div
				className="unhab-TimeProgressChartLegendItem-color"
				style={{backgroundColor: color}}
			/>
			<div className="unhab-TimeProgressChartLegendItem-title" title={title}>
				{title}
			</div>
		</div>
	);
};

TimeProgressChartLegendItem.propTypes = {
	id: PropTypes.string,
	color: PropTypes.string,
	name: PropTypes.string,
	showFeatureKey: PropTypes.bool,
};

const TimeProgressChartLegend = ({data, showFeatureKey}) => {
	return data?.length ? (
		<div className="unhab-TimeProgressChartLegend">
			{data.map(item => (
				<TimeProgressChartLegendItem
					key={item.id}
					{...item}
					showFeatureKey={showFeatureKey}
				/>
			))}
		</div>
	) : null;
};

TimeProgressChartLegend.propTypes = {
	data: PropTypes.array,
	showFeatureKey: PropTypes.bool,
};

export default TimeProgressChartLegend;
