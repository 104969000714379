import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const activeLayers =
		Select.maps.getMapLayersStateByMapKey(state, ownProps.mapKey) || [];

	const availableLayers =
		Select.unhab.layers.getAvailableLayersByAttributeKeys(
			ownProps.attributes,
		) || [];
	const activeLayersLayerTemplateKeys =
		availableLayers?.map(availableLayer => availableLayer?.layerTemplateKey) ||
		[];
	const activeLayersLayerKeys =
		availableLayers?.map(availableLayer => availableLayer?.key) || [];

	const activeIndicatorsLayers = activeLayers.filter(
		l =>
			activeLayersLayerTemplateKeys.includes(l.layerTemplateKey) ||
			activeLayersLayerKeys.includes(l.key),
	);

	return {
		availableLayers: availableLayers,
		attributeSet: Select.attributeSets.getByKey(
			state,
			ownProps.attributeSetKey,
		),
		activeLayers: activeIndicatorsLayers,
	};
};

const mapDispatchToPropsFactory = () => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory,
)(Presentation);
