import PropTypes from 'prop-types';
import MapLegendTitle from '../../components/MapLegendTitle';
import MapLayerLegend from '../../components/MapLayerLegend';
import MapLegendScale from '../../components/MapLegendScale';
import MapLegendClasses from '../../components/MapLegendClasses';

const XCubeLegend = ({layerDefinition, type, image}) => {
	const title = layerDefinition?.metadata?.layerTemplate?.data?.nameDisplay;
	const legend = layerDefinition?.metadata?.legend;
	let content = null;
	if (legend) {
		const {min, max} = legend;
		if (type === 'classes') {
			content = (
				<MapLegendClasses
					intervals={[{interval: [min]}, {interval: [min, max]}]}
					image={image}
				/>
			);
		} else {
			content = <MapLegendScale values={[min, max]} image={image} />;
		}
	}

	return (
		<MapLayerLegend expandable={false}>
			<MapLegendTitle>{title}</MapLegendTitle>
			{content}
		</MapLayerLegend>
	);
};

XCubeLegend.propTypes = {
	image: PropTypes.string,
	layerDefinition: PropTypes.object,
	layerState: PropTypes.object,
	title: PropTypes.string,
	type: PropTypes.string,
};

export default XCubeLegend;
