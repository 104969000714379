import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const title = Select.unhab.layers.getLayerName(ownProps.layerState);
	const relativeAttributeKey =
		ownProps?.layerState?.options?.attributes?.relative;
	const relativeAttribute = Select.attributes.getByKey(
		state,
		relativeAttributeKey,
	);
	return {
		title: title,
		unit: relativeAttribute?.data?.unit,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
