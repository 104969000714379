import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Selectors from '../../../../state/Select';
import Actions from '../../../../state/Action';

const mapStateToProps = state => {
	const activeSetKey = Selectors.maps.getActiveSetKey(state);
	const activeMapSet = Selectors.maps.getMapSetByKey(state, activeSetKey);
	return {
		activeMapSet,
	};
};

const mapDispatchToProps = () => {
	return (dispatch, ownProps) => {
		return {
			updateView: (update, mapKey) => {
				dispatch(Actions.maps.updateMapAndSetView(mapKey, update));
			},
			onLayerClick: (
				mapKey,
				layerKey,
				selectedFeatureKeys,
				position,
				feature,
			) => {
				if (!ownProps.pointAddingEnabled) {
					dispatch(
						Actions.maps.setLayerSelectedFeatureKeys(
							mapKey,
							layerKey,
							selectedFeatureKeys,
						),
					);

					if (feature) {
						dispatch(Actions.xCubeTimeSeries.load(mapKey, feature));
					}
				}
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
