import {commonReducers} from '@gisatcz/ptr-state';
import ActionTypes from '../../constants/ActionTypes';

const INITIAL_STATE = {
	byKey: {},
	colorBars: {},
};

const addColorBars = (state, action) => {
	return {
		...state,
		colorBars: {
			...state.colorBars,
			byName: action.colorBars,
			groups: action.groups,
		},
	};
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.XCUBE_LAYERS.ADD:
			return commonReducers.add(state, action);
		case ActionTypes.XCUBE_LAYERS.ADD_COLORBARS:
			return addColorBars(state, action);
		default:
			return state;
	}
};
