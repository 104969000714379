import {connect} from '@gisatcz/ptr-state';
import Select from '../../state/Select';

import Presentation from './presentation';

const mapStateToProps = state => {
	const routerState = Select.router.getCurrent(state);
	return {
		page: routerState?.name,
	};
};

const mapDispatchToProps = () => {
	return () => {
		return {};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
