import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const mapStateToProps = state => {
	return {
		features: Select.xCubeFeatures.getAll(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onDeleteFeature: (mapKey, key) => {
			dispatch(Action.xCubeLayers.deleteFeature(mapKey, key));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
