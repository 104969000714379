import {commonSelectors} from '@gisatcz/ptr-state';

const getSubstate = state => state.unhab.benchmarkComparisonTypes;

const getActiveKey = commonSelectors.getActiveKey(getSubstate);
const getActive = commonSelectors.getActive(getSubstate);
const getAll = commonSelectors.getAll(getSubstate);
export default {
	getActiveKey,
	getActive,
	getAll,
};
