import PropTypes from 'prop-types';
import Select from '../../Select';

import './style.scss';

const ViewSelect = ({views, activeViewKey, onViewKeyChange}) => {
	return (
		<Select
			lightweight
			inverse
			large
			models={views}
			activeKey={activeViewKey}
			onChange={onViewKeyChange}
			className="unhab-ViewSelect"
		/>
	);
};

ViewSelect.propTypes = {
	views: PropTypes.array,
	activeViewKey: PropTypes.string,
	onViewKeyChange: PropTypes.func,
};
export default ViewSelect;
