export default {
	cartoLight: {
		key: 'cartoLight',
		thumbnail: 'cartoDB_VoyagerNoLabels',
		name: 'Base',
		type: 'wmts',
		options: {
			url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png',
		},
	},
	cartoLightNoLabels: {
		key: 'cartoLightNoLabels',
		thumbnail: 'cartoDB_VoyagerNoLabels',
		name: 'Base (no labels)',
		type: 'wmts',
		defaultLight: true,
		options: {
			url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png',
		},
	},
	cartoDark: {
		key: 'cartoDark',
		thumbnail: 'cartoDB_DarkMatter',
		name: 'Dark (no labels)',
		type: 'wmts',
		defaultDark: true,
		options: {
			url: 'https://{s}.basemaps.cartocdn.com/rastertiles/dark_nolabels/{z}/{x}/{y}.png',
		},
	},
	esri_WorldTopoMap: {
		key: 'esri_WorldTopoMap',
		thumbnail: 'esri_WorldTopoMap',
		name: 'Topomap',
		type: 'wmts',
		options: {
			url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
			maxNativeZoom: 16,
		},
	},
	esri_WorldImagery: {
		key: 'esri_WorldImagery',
		thumbnail: 'esri_WorldImagery',
		name: 'Aerial',
		type: 'wmts',
		options: {
			url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
			maxNativeZoom: 17,
		},
	},
	openStreetMap_Mapnik: {
		key: 'openStreetMap_Mapnik',
		thumbnail: 'openStreetMap_Mapnik',
		name: 'OpenStreetMap',
		type: 'wmts',
		options: {
			url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
		},
	},
};
