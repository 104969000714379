import PropTypes from 'prop-types';
import DataLayersGroup from '../components/DataLayersGroup';
import './style.scss';
import {useEffect} from 'react';
import DataLayerControl from '../components/DataLayerControl';

const XCubeLayersControl = ({
	activeLayers,
	availableLayers,
	onMount,
	mapKey,
}) => {
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount();
		}
	}, []);

	return (
		<DataLayersGroup title="Thematic layers">
			{availableLayers?.map(group => {
				const [name, layers] = group;
				return (
					<DataLayersGroup key={name} title={name} secondary>
						{layers?.map(layer => {
							const active = activeLayers?.find(
								activeLayer =>
									activeLayer?.key &&
									layer?.key &&
									activeLayer?.key === layer?.key,
							);

							return (
								<DataLayerControl
									key={layer.key}
									mapKey={mapKey}
									active={!!active}
									data={layer}
									type={'radio'}
									initialized={true}
									activeLayers={activeLayers}
								/>
							);
						})}
					</DataLayersGroup>
				);
			})}
		</DataLayersGroup>
	);
};

XCubeLayersControl.propTypes = {
	activeLayers: PropTypes.array,
	availableLayers: PropTypes.array,
	mapKey: PropTypes.string,
	onMount: PropTypes.func,
};

export default XCubeLayersControl;
