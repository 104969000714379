import {
	Children,
	cloneElement,
	isValidElement,
	useContext,
	useRef,
} from 'react';
import PropTypes from 'prop-types';
import Context from '@gisatcz/cross-package-react-context';

const defaultHoverContext = 'HoverContext';

const NivoChartHover = ({children, getHoverContent}) => {
	const hoverContext = Context.getContext(defaultHoverContext);
	const context = useContext(hoverContext);
	const hoverPointIdRef = useRef(null);

	const onHover = evt => {
		if (evt && hoverPointIdRef.current !== evt.id) {
			const popupContent =
				(evt && getHoverContent && getHoverContent(evt, context)) || null;
			if (
				popupContent &&
				(isValidElement(popupContent) || popupContent?.popup?.content)
			) {
				const hoverItem = {
					key: 'nivoChartHover',
					x: evt.x,
					y: evt.y,
					point: evt,
				};

				context.hoveredItems = [hoverItem];
				context.onHover([hoverItem], {
					popup: {
						x: evt.x,
						y: evt.y,
						content: popupContent,
					},
				});
			} else {
				context.onHoverOut();
			}
			hoverPointIdRef.current = evt.id;
		} else if (!evt) {
			hoverPointIdRef.current = null;
			context.hoveredItems = [];
			context.onHoverOut();
		}
	};
	const childrenElms = Children.map(children, child =>
		cloneElement(child, {
			...child.props,
			onHover,
			hovered: context?.hoveredItems,
		}),
	);

	return <>{childrenElms}</>;
};

NivoChartHover.propTypes = {
	children: PropTypes.node,
	getHoverContent: PropTypes.func,
};

export default NivoChartHover;
