import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import moment from 'moment';
import 'rc-slider/assets/index.css';
import {DAY_IN_SECONDS} from '../constants';

import TimeRangeOverviewChart from '../TimeRangeOverviewChart';

import './style.scss';

/**
 * @param startDate {string} ISO 8601 date. Default start date.
 * @param endDate {string} ISO 8601 date. Default start date.
 * @param currentStartDate {string} ISO 8601 date
 * @param currentEndDate {string} ISO 8601 date
 * @param onTimeRangeChange {func} Callback when time range was changed
 * @param ChartComponent {React.FunctionComponent}
 * @param chartComponentSettings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param data {Array} following nivo line chart input data format (https://nivo.rocks/line/)
 * @param metadata {Object}
 * @param metadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param mainChartMetadata {Object}
 * @param mainChartMetadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param step {number} Time range slider step (in days)
 * @param displayTimeFormat {string}
 * @returns {JSX.Element|null}
 */
const TimeRangeSelector = ({
	startDate,
	endDate,
	currentStartDate,
	currentEndDate,
	onTimeRangeChange,
	ChartComponent,
	chartComponentSettings,
	data,
	metadata,
	mainChartMetadata,
	step,
	displayTimeFormat,
}) => {
	const startingSecond = moment(startDate).unix();
	const endingSecond = moment(endDate).unix();
	const currentStartingSecond = moment(currentStartDate).unix();
	const currentEndingSecond = moment(currentEndDate).unix();

	const onRangeChange = range => {
		const [selectedStartingSecond, selectedEndingSecond] = range;
		const selectedStartDate = moment
			.unix(selectedStartingSecond)
			.utc()
			.format('YYYY-MM-DDTHH:mm:ssZ');
		const selectedEndDate = moment
			.unix(selectedEndingSecond)
			.utc()
			.format('YYYY-MM-DDTHH:mm:ssZ');
		onTimeRangeChange(selectedStartDate, selectedEndDate);
	};

	const style = {
		marginLeft: mainChartMetadata?.settings?.margin?.left || 0,
		marginRight: mainChartMetadata?.settings?.margin?.right || 0,
	};

	const start = displayTimeFormat
		? moment(startDate)?.format(displayTimeFormat)
		: moment(startDate)?.format('D MMMM YYYY');

	const end = displayTimeFormat
		? moment(endDate)?.format(displayTimeFormat)
		: moment(endDate)?.format('D MMMM YYYY');

	return (
		<div
			className="ptr-TimeRangeSelector"
			style={style}
			data-html2canvas-ignore
		>
			<div className="ptr-TimeRangeSelector-content">
				<TimeRangeOverviewChart
					ChartComponent={ChartComponent}
					chartComponentSettings={chartComponentSettings}
					data={data}
					metadata={metadata}
				/>
				<Slider
					className="ptr-TimeRangeSlider"
					range
					allowCross={false}
					value={[currentStartingSecond, currentEndingSecond]}
					min={startingSecond}
					max={endingSecond}
					step={step * DAY_IN_SECONDS}
					draggableTrack
					onChange={onRangeChange}
				/>
			</div>
			<div className="ptr-TimeRangeSelector-labels">
				<span>{start}</span>
				<span>{end}</span>
			</div>
		</div>
	);
};

TimeRangeSelector.propTypes = {
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	currentStartDate: PropTypes.string,
	currentEndDate: PropTypes.string,
	onTimeRangeChange: PropTypes.func,
	ChartComponent: PropTypes.func,
	chartComponentSettings: PropTypes.object,
	data: PropTypes.array,
	metadata: PropTypes.object,
	mainChartMetadata: PropTypes.object,
	step: PropTypes.number,
	displayTimeFormat: PropTypes.string,
};

export default TimeRangeSelector;
