import PropTypes from 'prop-types';
import VectorMapTooltip from './VectorMapTooltip';
import './style.scss';

const MapTooltip = ({event, vector, mapKey}) => {
	const vectorIDs = [];
	const vectorContent = vector.reduce((acc, val, i) => {
		// vector tooltip should come here
		if (!vectorIDs.includes(val.layer.id)) {
			vectorIDs.push(val.layer.id);
			return [
				...acc,
				<VectorMapTooltip
					key={`${i}_${val.layer.props.layerKey || val.layer.props.key}`}
					{...{layer: val, event, mapKey}}
				></VectorMapTooltip>,
			];
		} else {
			return acc;
		}
	}, []);
	const isEmpty = vectorContent?.length === 0;
	return !isEmpty ? <div>{vectorContent}</div> : null;
};

MapTooltip.propTypes = {
	event: PropTypes.object,
	vector: PropTypes.array,
	raster: PropTypes.array,
	mapKey: PropTypes.string,

	children: PropTypes.node,
};

export default MapTooltip;
