import ActionTypes from '../../constants/ActionTypes';
// import {stateManagement} from '@gisatcz/ptr-utils';

// App specific reducers
const INITIAL_STATE = {
	currentPointFeatureIndex: 0,
	byKey: {},
};

const addAsObject = (state, features) => {
	return {
		...state,
		byKey: state.byKey ? {...state.byKey, ...features} : features,
	};
};

const incrementIndex = state => {
	return {
		...state,
		currentPointFeatureIndex: state.currentPointFeatureIndex + 1,
	};
};

const update = (state, features) => {
	return {
		...state,
		byKey: features,
	};
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.XCUBE_FEATURES.ADD_AS_OBJECT:
			return addAsObject(state, action.features);
		case ActionTypes.XCUBE_FEATURES.INCREMENT_INDEX:
			return incrementIndex(state);
		case ActionTypes.XCUBE_FEATURES.UPDATE:
			return update(state, action.features);
		default:
			return state;
	}
};
