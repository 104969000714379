import PropTypes from 'prop-types';
import classNames from 'classnames';
import {forIn as _forIn} from 'lodash';
import MapLegendValueItem from './MapLegendValueItem';
import './style.scss';

const maximalCountForMinimize = 4;

const MapLegendValues = ({values, expanded}) => {
	const classes = classNames('ptr-MapLegendValues', {
		'is-expanded': expanded,
	});

	let content = [];
	let index = 0;
	_forIn(values, (value, key) => {
		const el = (
			<MapLegendValueItem
				key={key}
				color={value.fill || value.color}
				label={value.name || key}
			/>
		);
		if (!expanded && index < maximalCountForMinimize) {
			content.push(el);
		} else if (expanded) {
			content.push(el);
		}
		index++;
	});

	if (!expanded && index > content.length) {
		content.push(
			<div className="ptr-MapLegendExpandInfo">
				Expand by click for more classes.
			</div>,
		);
	}

	return <div className={classes}>{content}</div>;
};

MapLegendValues.propTypes = {
	values: PropTypes.object,
	expanded: PropTypes.bool,
};

export default MapLegendValues;
