import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	const mapSetKey = Select.maps.getActiveSetKey(state);
	const layers = Select.maps.getMapSetActiveMapLayers(state, mapSetKey);
	const layer = layers?.[layers?.length - 2]; // Get layer below analytical units
	const data = Select.xCubeTimeSeries.getTimeProgressData(
		state,
		layer,
		ownProps.configuration,
	);

	return {
		currentLayerKey: layer?.key,
		data: data?.data,
		title: data?.title,
		subtitle: data?.subtitle,
		multipleFeatures:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.key?.length >
			1,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onCurrentLayerChange: () => {
			dispatch(Action.xCubeTimeSeries.loadForSelectedFeatures());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
