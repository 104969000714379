import PropTypes from 'prop-types';
import MapLegendScale from '../../components/MapLegendScale';
import MapLegendClasses from '../../components/MapLegendClasses';
import MapLegendTitle from '../../components/MapLegendTitle';
import MapLayerLegend from '../../components/MapLayerLegend';
import MapLegendValues from '../../components/MapLegendValues';

const WMSLayer = ({layerState}) => {
	const title = layerState?.metadata?.layerTemplate?.data?.nameDisplay;
	const attributeStyle =
		layerState?.style?.data?.definition?.rules?.[0]?.styles?.[1];
	let content = null;
	const isExpanded = false;

	if (attributeStyle?.valueClasses) {
		content = (
			<MapLegendClasses
				isExpanded={isExpanded}
				intervals={attributeStyle?.valueClasses}
			/>
		);
	} else if (attributeStyle?.attributeScale) {
		content = (
			<MapLegendScale
				isExpanded={isExpanded}
				values={attributeStyle?.attributeScale}
			/>
		);
	} else if (attributeStyle?.values || attributeStyle?.attributeValues) {
		content = (
			<MapLegendValues
				isExpanded={isExpanded}
				values={attributeStyle?.values || attributeStyle?.attributeValues}
			/>
		);
	} else {
		return null;
	}

	return (
		<MapLayerLegend>
			<MapLegendTitle>{title}</MapLegendTitle>
			{content}
		</MapLayerLegend>
	);
};

WMSLayer.propTypes = {
	expanded: PropTypes.bool,
	layerDefinition: PropTypes.object,
	layerState: PropTypes.object,
};

export default WMSLayer;
