import HeaderSelect from '../../../../common/HeaderSelect';

const options = [
	{
		value: 'xCubeLimassol',
		label: 'CityDataCube Explorer',
	},
];

const value = options[0];

const AppSelect = () => {
	return (
		<HeaderSelect
			icon="ri-applications"
			options={options}
			value={value}
			menuPosition="fixed"
			menuWidth="15rem"
			highlighted
			disabledSelection
			onChange={() => {}}
		/>
	);
};

AppSelect.propTypes = {};

export default AppSelect;
