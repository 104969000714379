import {createSelector} from 'reselect';
import {Select as CommonSelect} from '@gisatcz/ptr-state';
import {limassolAreasNameColumnName} from '../../constants/app';
import featuresSelectors from '../xCubeFeatures/selectors';
import utils from '../../utils';

const getSubstate = state => state.xCubeTimeSeries;

const getAllTimeSeriesAsObject = state => state.xCubeTimeSeries.byFeatureKey;

const getTimeSeriesForSelectedFeatures = createSelector(
	[
		CommonSelect.selections.getActive,
		getAllTimeSeriesAsObject,
		featuresSelectors.getAllAsObject,
		(state, layer) => layer,
	],
	(activeSelection, timeseries, allFeatures, layer) => {
		const selectedFeatureKeys = activeSelection?.data?.featureKeysFilter?.keys;
		if (selectedFeatureKeys?.length && timeseries && layer?.key) {
			const features = [];
			selectedFeatureKeys.forEach(key => {
				const feature = allFeatures?.[key];
				const color = utils.getSelectedFeaturePrimaryColor(
					key,
					activeSelection?.data,
				);
				const timeserie = timeseries[key]?.[layer.key];
				if (timeserie) {
					features.push({
						key,
						featureProps: feature?.properties,
						timeserie,
						color,
					});
				}
			});

			return features?.length ? features : null;
		} else {
			return null;
		}
	},
);

/**
 * @param propKeys {array} List of feature property keys, which should be used for chart
 */
const getTimeProgressData = createSelector(
	[getTimeSeriesForSelectedFeatures, (state, layer) => layer],
	(timeSeriesByFeature, layer) => {
		const data = [];
		if (timeSeriesByFeature?.length) {
			timeSeriesByFeature.forEach(feature => {
				const {key, featureProps, timeserie, color} = feature;
				data.push({
					id: key,
					name: featureProps?.[limassolAreasNameColumnName],
					data: timeserie?.map(item => {
						return {x: item.time, y: item.mean};
					}), // TODO get the data better, now just mean is selected
					color,
				});
			});
			data;
		}

		return {
			data,
			title: layer?.metadata?.variable,
			subtitle: layer?.metadata?.group,
		};
	},
);

export default {
	getSubstate,
	getTimeProgressData,
};
