import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';

const mapStateToProps = state => {
	const links = Select.getLinks();
	return {
		activeViewKey: Select.views.getActiveKey(state),
		mapSetKey: Select.maps.getActiveSetKey(state),
		isXCubeApp: Select.isXCubeApp(),
		visatUrl: links.visat,
	};
};

const mapDispatchToProps = () => {
	return () => {
		return {};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
