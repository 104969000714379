import PropTypes from 'prop-types';
import {DeckGlMap, MapSet, PresentationMap} from '@gisatcz/ptr-maps';
import {connects} from '@gisatcz/ptr-state';
import MapWrapper from '../../../common/Map/MapWrapper';
import MapContainer from './MapContainer';
import '../../../common/Map/style.scss';

const ConnectedMap = MapContainer(PresentationMap);
const ConnectedMapSet = connects.MapSet(MapSet);

const Maps = ({activeMapSet, updateView, onLayerClick}) => {
	return (
		<div className="unhab-Map">
			<ConnectedMapSet
				onLayerClick={onLayerClick}
				stateMapSetKey={activeMapSet?.key}
				mapComponent={DeckGlMap}
				connectedMapComponent={ConnectedMap}
				wrapper={MapWrapper}
				wrapperProps={{
					updateView,
					mapSetKey: activeMapSet?.key,
				}}
			/>
		</div>
	);
};

Maps.propTypes = {
	activeMapSet: PropTypes.object,
	updateView: PropTypes.func,
	onLayerClick: PropTypes.func,
};

export default Maps;
