import PropTypes from 'prop-types';
import classnames from 'classnames';
import ColorDot from '../ColorDot';
import './style.scss';

const ColorDotLabel = ({color, noColor, large, title, onClick, children}) => {
	const classes = classnames('ptr-ColorDotLabel', {
		'is-large': large,
		'is-clickable': !!onClick,
	});

	return (
		<div className={classes} onClick={onClick} title={title}>
			{noColor ? null : <ColorDot color={color} />}
			<div className="ptr-ColorDotLabel-name">{children}</div>
		</div>
	);
};

ColorDotLabel.propTypes = {
	color: PropTypes.string,
	children: PropTypes.node,
	large: PropTypes.bool,
	noColor: PropTypes.bool,
	title: PropTypes.string,
	onClick: PropTypes.func,
};

export default ColorDotLabel;
