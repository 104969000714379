import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';

const componentId = 'XCubePanel';

const mapStateToProps = state => {
	return {
		open: Select.components.get(state, componentId, 'open'),
		selectedFeatureKeys:
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys,
		allFeatures: Select.xCubeFeatures.getAll(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setOpen: open => dispatch(Action.components.set(componentId, 'open', open)),
		onUnmount: () => {
			dispatch(Action.selections.setActiveSelectionFeatureKeysFilterKeys([]));
			dispatch(Action.xCubeLayers.deleteFeature('map-1', null));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
