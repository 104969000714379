import PropTypes from 'prop-types';
import Home from '../pages/Home';
import App from '../pages/App';
import Unknown from '../pages/Unknown';

const getActiveScreenComponent = page => {
	switch (page) {
		case 'home':
			return <Home />;
		case 'app':
			return <App />;
		default:
			return <Unknown />;
	}
};

const Router = ({page}) => {
	const ActiveScreenComponent = getActiveScreenComponent(page);
	return <>{ActiveScreenComponent}</>;
};

Router.propTypes = {
	page: PropTypes.string,
};

export default Router;
