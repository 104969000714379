import ActionTypes from '../../../constants/ActionTypes';
import datasets from '../../../data/datasets';

function add() {
	return dispatch => {
		dispatch(actionAdd(datasets));
	};
}

const actionAdd = data => {
	return {
		type: ActionTypes.UNHAB.DATASETS.ADD,
		data,
	};
};

const actionSetActiveKeys = keys => {
	return {
		type: ActionTypes.UNHAB.DATASETS.SET_ACTIVE_KEYS,
		keys,
	};
};

export default {
	add,
	setActiveKeys: actionSetActiveKeys,
};
