import PropTypes from 'prop-types';
import './style.scss';

const ColorDot = ({color}) => {
	return (
		<div className="ptr-ColorDot" style={{background: color || '#dddddd'}} />
	);
};

ColorDot.propTypes = {
	color: PropTypes.string,
};

export default ColorDot;
