import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import utils from '../../../../../utils';

const mapStateToProps = (state, ownProps) => {
	const activeLayers = Select.maps.getMapLayersStateByMapKey(
		state,
		ownProps.mapKey,
	);
	const activeLayer = activeLayers?.find(
		activeLayer =>
			activeLayer?.key &&
			ownProps.layer?.key &&
			activeLayer?.key === ownProps.layer?.key,
	);

	const layers = Select.maps.getMapLayers(ownProps.mapKey, [activeLayer]);

	const activeMapLayer = layers?.[0];

	return {
		activeTimeKey: activeMapLayer
			? utils.getQueryParamValue(activeMapLayer.options.url, 'time')
			: null,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onLayerTimeChange: time => {
			dispatch(
				Action.xCubeLayers.changeMapLayerTime(
					ownProps.mapKey,
					ownProps.layer?.key,
					time,
				),
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
