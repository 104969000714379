import {
	limassolAreasFidColumnName,
	limassolAreasLayerKey,
	limassolAreasSelectionKey,
} from '../../constants/app';
import publicGreenAreas from '../geometries/limassolPublicGreenAreas.json';

export default {
	nameDisplay: 'Limassol public green areas & points',
	key: limassolAreasLayerKey,
	type: 'vector',
	options: {
		features: publicGreenAreas.features,
		fidColumnName: limassolAreasFidColumnName,
		pointAsMarker: true,
		selectable: true,
		selected: {
			[limassolAreasSelectionKey]: {},
		},
		style: {
			rules: [
				{
					styles: [
						{
							fill: '#cccccc',
							fillOpacity: 0.3,
							outlineColor: '#00ff00',
							outlineWidth: 2,
							size: 15,
							name: 'Public green area',
						},
						{
							attributeKey: 'point',
							attributeValues: {
								1: {
									fillOpacity: 1,
									size: 10,
									fill: '#00ff00',
									outlineColor: '#00ff00',
									name: 'Custom point',
								},
							},
						},
					],
				},
			],
		},
	},
};
