import {Select as CommonSelect} from '@gisatcz/ptr-state';
import router from './router/selectors';
import unhab from './unhab/selectors';
import xCubeLayers from './xCubeLayers/selectors';
import xCubeFeatures from './xCubeFeatures/selectors';
import xCubeTimeSeries from './xCubeTimeSeries/selectors';
import {defaultLinks, xCubeApp} from '../constants/app';

const isXCubeApp = () => {
	if (Object.hasOwn(window?.runtimeConfig || {}, 'xCubeApp')) {
		return window?.runtimeConfig?.xCubeApp;
	} else {
		return xCubeApp;
	}
};

const getLinks = () => {
	return window?.runtimeConfig?.links || defaultLinks;
};

export default {
	getLinks,
	isXCubeApp,

	...CommonSelect,
	router,
	unhab,
	xCubeLayers,
	xCubeFeatures,
	xCubeTimeSeries,
};
