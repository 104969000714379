import PropTypes from 'prop-types';
import moment from 'moment';
import {Icon} from '@gisatcz/ptr-atoms';
import './style.scss';

/**
 * @param startDate {String} ISO 8601 date
 * @param endDate {String} ISO 8601 date
 * @param mainChartMetadata {Object} Metadata of main chart
 * @param mainChartMetadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param format {String} Date format
 * @returns {JSX.Element|null}
 */
const TimeRangeLabel = ({startDate, endDate, mainChartMetadata, format}) => {
	const style = {
		marginLeft: mainChartMetadata?.settings?.margin?.left || 0,
		marginRight: mainChartMetadata?.settings?.margin?.right || 0,
	};

	const start = format
		? moment(startDate)?.format(format)
		: moment(startDate)?.format('D MMMM YYYY');

	const end = format
		? moment(endDate)?.format(format)
		: moment(endDate)?.format('D MMMM YYYY');

	if (startDate && endDate) {
		return (
			<div style={style} className="ptr-TimeRangeLabel">
				<Icon icon="calendar" />
				<span>{start}</span>
				<span className="ptr-TimeRangeLabel-divider">-</span>
				<span>{end}</span>
			</div>
		);
	} else {
		return null;
	}
};

TimeRangeLabel.propTypes = {
	format: PropTypes.string,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	mainChartMetadata: PropTypes.object,
};

export default TimeRangeLabel;
