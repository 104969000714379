import PropTypes from 'prop-types';
import MapLegendScale from '../components/MapLegendScale';
import MapLegendValues from '../components/MapLegendValues';
import MapLegendClasses from '../components/MapLegendClasses';

const AttributeLegend = ({style, expanded, unit}) => {
	if (style.attributeClasses) {
		return (
			<MapLegendClasses
				unit={unit}
				expanded={expanded}
				intervals={style.attributeClasses}
			/>
		);
	} else if (style.attributeScale) {
		return (
			<MapLegendScale
				expanded={expanded}
				values={style.attributeScale?.inputInterval}
				colors={style.attributeScale?.outputInterval}
			/>
		);
	} else if (style.attributeValues) {
		return (
			<MapLegendValues expanded={expanded} values={style.attributeValues} />
		);
	} else {
		return null;
	}
};

AttributeLegend.propTypes = {
	expanded: PropTypes.bool,
	style: PropTypes.object,
	unit: PropTypes.string,
};

export default AttributeLegend;
