import {commonSelectors} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';
import {groupBy as _groupBy, forIn as _forIn} from 'lodash';

const getSubstate = state => state.xCubeLayers;
const getAll = commonSelectors.getAll(getSubstate);
const getColorBarByName = (state, name) =>
	state.xCubeLayers.colorBars.byName?.[name];
const getLegendGroups = state => state.xCubeLayers.colorBars.groups;

const getAllGrouped = createSelector([getAll], layers => {
	if (layers?.length) {
		const grouped = _groupBy(layers, layer => layer.metadata.group);
		const groups = [];
		_forIn(grouped, (data, group) => {
			groups.push([group, data]);
		});
		return groups?.length ? groups : null;
	} else {
		return null;
	}
});

const getColorBarImageByName = createSelector([getColorBarByName], colorBar => {
	return colorBar?.image || null;
});

const getLegendTypeByName = createSelector(
	[getLegendGroups, (state, legendName) => legendName],
	(groups, legendName) => {
		if (groups?.length && legendName) {
			const selectedGroup = groups.find(group =>
				group.colorBars.includes(legendName),
			);
			return selectedGroup?.type || null;
		} else {
			return null;
		}
	},
);

export default {
	getAll,
	getAllGrouped,
	getColorBarImageByName,
	getLegendTypeByName,
};
