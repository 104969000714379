import {connect, setRecomputeState} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	setRecomputeState(state);
	return {
		mapLayersDefinitions: Select.maps.getMapLayers(ownProps.mapKey),
		mapLayersStateEnhanced: Select.unhab.layers.getMapLayersStateEnhanced(
			state,
			ownProps.mapKey,
		),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
