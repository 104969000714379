import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		availableLayers: Select.unhab.layers.getAvailableOutlinesLayers(state),
		activeLayers: Select.maps.getMapLayersStateByMapKey(state, ownProps.mapKey),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
