import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const Radio = ({
	children,
	className,
	onChange,
	onClick,
	checked,
	disabled,
	htmlTitle,
}) => {
	const classes = classnames(
		'ptr-Radio',
		{
			'is-disabled': disabled,
		},
		className,
	);

	return (
		<label className={classes} title={htmlTitle}>
			<input
				type="radio"
				onChange={onChange}
				onClick={() => {
					if (typeof onClick === 'function') {
						onClick();
					}
				}}
				checked={checked || false}
				disabled={disabled || false}
			/>
			<span>{children}</span>
		</label>
	);
};

Radio.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	disabled: PropTypes.bool,
	htmlTitle: PropTypes.string,
};

export default Radio;
