import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';

import Presentation from './presentation';

import './style.scss';

const mapStateToProps = (state, ownProps) => {
	const map = Select.maps.getMapByKey(state, ownProps.mapKey);

	return {
		backgroundLayer: ownProps.backgroundLayer || map?.data?.backgroundLayer,
	};
};

export default connect(mapStateToProps)(Presentation);
