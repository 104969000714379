import {defaultPeriod} from '../../constants/app';

export default [
	{
		key: 'b8de4ca6-b457-4649-b266-dcb9287e4a23',
		data: {
			period: defaultPeriod,
		},
	},
];
