import PropTypes from 'prop-types';
import LayerSettingsItem from '../LayerSettingsItem';
import OpacitySlider from '../../../../../OpacitySlider';
import './style.scss';

const LayerOpacityControl = ({
	opacity,
	onChange,
	layerKey,
	layerTemplateKey,
}) => {
	return (
		<LayerSettingsItem title="Opacity" key="opacity">
			<OpacitySlider
				value={opacity >= 0 ? opacity * 100 : 100}
				onChange={opacity => onChange(opacity, layerKey, layerTemplateKey)}
			/>
		</LayerSettingsItem>
	);
};

LayerOpacityControl.propTypes = {
	opacity: PropTypes.number,
	onChange: PropTypes.func,
	layer: PropTypes.object,
	layerKey: PropTypes.string,
	layerTemplateKey: PropTypes.string,
};

export default LayerOpacityControl;
