import {getRouter} from '../../../router';

const onLinkClick = evt => {
	evt.preventDefault();
	evt.stopPropagation();
	const router = getRouter();
	const homeUrl = router.pathFor('home'); // => /hello/John
	router.nav(homeUrl); // opens page `/hello/John` that shows alert `Hello John!`
};

const HomePage = () => {
	return (
		<div>
			<h3>Unknown url.</h3>
			<span>
				Please follow to the{' '}
				<a href={'/'} onClick={onLinkClick}>
					homepage
				</a>
			</span>
		</div>
	);
};

export default HomePage;
