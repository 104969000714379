import ActionTypes from '../../../constants/ActionTypes';

// App specific reducers
const INITIAL_STATE = {};

const addStructure = (state, structure) => {
	return {
		...state,
		structure,
	};
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UNHAB.ADMSTRUCTURE.ADD:
			return addStructure(state, action.structure);
		default:
			return state;
	}
};
