import ActionTypes from '../../constants/ActionTypes';
import {limassolAreasFidColumnName} from '../../constants/app';
import utils from '../../utils';
import Select from '../Select';
import path from 'path';

function loadForSelectedFeatures() {
	return (dispatch, getState) => {
		const state = getState();
		const mapSetKey = Select.maps.getActiveSetKey(state);
		const mapKey = Select.maps.getMapSetActiveMapKey(state, mapSetKey);
		const featureKeys =
			Select.selections.getActive(state)?.data?.featureKeysFilter?.keys;
		if (featureKeys?.length) {
			const features = Select.xCubeFeatures.getByKeys(state, featureKeys);
			if (features?.length) {
				features.forEach(feature => dispatch(load(mapKey, feature)));
			}
		}
	};
}

function load(mapKey, feature) {
	return (dispatch, getState) => {
		const state = getState();
		const layers = Select.maps.getMapLayersStateByMapKey(state, mapKey);
		const layer = layers?.[layers?.length - 2]; // Select layer below analytical units
		if (feature && layer) {
			const groupKey = layer.metadata?.groupKey;
			const variable = layer.metadata?.variable;
			const startDate = layer.times?.[0];
			const endDate = layer.times?.[layer?.times?.length - 1];
			if (groupKey && variable && startDate && endDate) {
				dispatch(
					ensure(layer?.key, groupKey, variable, startDate, endDate, feature),
				);
			}
		}
	};
}

function ensure(layerKey, groupKey, variable, startDate, endDate, feature) {
	return (dispatch, getState) => {
		const localConfig = Select.app.getCompleteLocalConfiguration(getState());
		const featureKey = feature?.properties?.[limassolAreasFidColumnName];
		const geometry = feature?.geometry;
		const query = {
			aggMethods: 'mean', // TODO use mean for now
			startDate,
			endDate,
		};
		const url = new URL(
			path.join('be-interface-xcube/query/cube-timeseries', groupKey, variable),
			`${localConfig.apiBackendProtocol}://${localConfig.apiBackendHost}`,
		).toString();

		return utils
			.request(url, 'POST', query, geometry)
			.then(data => {
				if (data?.result) {
					dispatch(actionAdd(layerKey, featureKey, data?.result));
				}
			})
			.catch(err => new Error(err));
	};
}

const actionAdd = (layerKey, featureKey, data) => {
	return {
		type: ActionTypes.XCUBE_TIME_SERIES.ADD,
		layerKey,
		featureKey,
		data,
	};
};

export default {
	add: actionAdd,
	load,
	loadForSelectedFeatures,
};
