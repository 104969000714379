import PropTypes from 'prop-types';
import ColorDotLabel from '../ColorDotLabel';
import './style.scss';

const SelectedFeatureLabel = ({color, large, name, title, onClick}) => {
	return (
		<ColorDotLabel large={large} color={color} title={title} onClick={onClick}>
			{name}
		</ColorDotLabel>
	);
};

SelectedFeatureLabel.propTypes = {
	color: PropTypes.string,
	name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	large: PropTypes.bool,
	title: PropTypes.string,
	onClick: PropTypes.func,
};

export default SelectedFeatureLabel;
