import queryString from 'query-string';

export const getParsedQueryString = urlQueryString => {
	if (!urlQueryString) {
		return {};
	}
	const parsed = queryString.parse(urlQueryString);
	return parsed || {};
};

export const getStoreFromRequest = request => {
	// store is inside request under Symbol "store"
	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol#symbols_and_for...in_iteration
	return request[Object.getOwnPropertySymbols(request)[0]];
};
