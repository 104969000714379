import PropTypes from 'prop-types';
import {cloneElement} from 'react';
import {ResponsiveLine} from '@nivo/line';

/**
 * @param data {Array} following nivo line chart input data format (https://nivo.rocks/line/)
 * @param metadata {Object}
 * @param metadata.settings {Object} following nivo line chart props (https://nivo.rocks/line/)
 * @param customSettings {Object} settings customization, following nivo line chart props (https://nivo.rocks/line/)
 * @param onHover {function}
 * @param Tooltip {React.FunctionComponent}
 * @returns {JSX.Element|null}
 */
const LineChart = ({data, metadata, customSettings, onHover, Tooltip}) => {
	const {settings} = metadata; // TODO if no settings, then basic settings?
	return data ? (
		<ResponsiveLine
			onMouseMove={onHover}
			sliceTooltip={() => {}}
			onMouseLeave={() => onHover()}
			tooltip={Tooltip || (() => {})}
			key={JSON.stringify(data.map(item => item.id))} // TODO hot fix for multiple points selection
			data={data}
			{...settings}
			{...customSettings}
			{...(customSettings?.pointSymbol
				? {
						pointSymbol: point => {
							return cloneElement(customSettings.pointSymbol, {
								point,
								hovered: customSettings.pointSymbol.props.hovered,
							});
						},
				  }
				: {})}
		/>
	) : null;
};

LineChart.propTypes = {
	data: PropTypes.array,
	metadata: PropTypes.object,
	customSettings: PropTypes.object,
	Tooltip: PropTypes.func,
	onHover: PropTypes.func,
};

export default LineChart;
