import ActionTypes from '../../../constants/ActionTypes';
import datasetSets from '../../../data/datasetSets';
import Select from '../../Select';
import datasetsActions from '../datasets/actions';

function add() {
	return dispatch => {
		dispatch(actionAdd(datasetSets));
	};
}

function setActive(key) {
	return (dispatch, getState) => {
		dispatch(actionSetActiveKey(key));
		const datasetKeys = Select.unhab.datasetSets.getDatasetKeysByKey(
			getState(),
			key,
		);
		dispatch(datasetsActions.setActiveKeys(datasetKeys));
	};
}

const actionAdd = data => {
	return {
		type: ActionTypes.UNHAB.DATASET_SETS.ADD,
		data,
	};
};

const actionSetActiveKey = key => {
	return {
		type: ActionTypes.UNHAB.DATASET_SETS.SET_ACTIVE_KEY,
		key,
	};
};

export default {
	add,
	setActive,
};
