import {connect, setRecomputeState as setState} from '@gisatcz/ptr-state';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	setState(state);

	return {
		backgroundLayer: Select.maps.getMapBackgroundLayer(ownProps.stateMapKey),
		layers: Select.maps.getMapLayers(ownProps.stateMapKey),
		viewport: Select.maps.getViewportByMapKey(state, ownProps.stateMapKey),
		view: Select.maps.getViewByMapKey(state, ownProps.stateMapKey),
		viewLimits: Select.maps.getViewLimitsByMapKey(state, ownProps.stateMapKey),
		mapKey: ownProps.stateMapKey,
	};
};

const mapDispatchToPropsFactory = () => {
	return (dispatch, ownProps) => {
		return {
			onMount: (mapWidth, mapHeight) => {
				dispatch(
					Action.maps.setMapViewport(ownProps.stateMapKey, mapWidth, mapHeight),
				);
				dispatch(Action.maps.use(ownProps.stateMapKey, null, null));
			},

			onResize: (mapWidth, mapHeight) => {
				dispatch(
					Action.maps.setMapViewport(ownProps.stateMapKey, mapWidth, mapHeight),
				);
				dispatch(Action.maps.use(ownProps.stateMapKey, null, null));
			},

			onUnmount: () => {
				dispatch(Action.maps.mapUseClear(ownProps.stateMapKey));
			},

			refreshUse: () => {},

			onViewChange: update => {
				dispatch(Action.maps.updateMapAndSetView(ownProps.stateMapKey, update));
			},

			onPropViewChange: (update, mapWidth, mapHeight) => {
				dispatch(
					Action.maps.setMapViewport(ownProps.stateMapKey, mapWidth, mapHeight),
				);
				dispatch(Action.maps.use(ownProps.stateMapKey, undefined, undefined));
			},

			resetHeading: () => {},

			onClick: (mapKey, props) => {
				dispatch(Action.maps.setMapSetActiveMapKey(ownProps.stateMapKey));
				dispatch(
					Action.xCubeLayers.handleMapClick(
						ownProps.stateMapKey,
						props.info.coordinate,
					),
				);
			},
			onLayerClick: (
				mapKey,
				layerKey,
				selectedFeatureKeys,
				position,
				options,
			) => {
				if (typeof ownProps.onLayerClick === 'function') {
					ownProps.onLayerClick(
						mapKey,
						layerKey,
						selectedFeatureKeys,
						position,
						options,
					);
				} else {
					dispatch(
						Action.maps.setLayerSelectedFeatureKeys(
							ownProps.stateMapKey,
							layerKey,
							selectedFeatureKeys,
						),
					);
				}
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToPropsFactory);
