import PropTypes from 'prop-types';
import './style.scss';

const LayerSettings = ({children}) => {
	return <div className="unhab-LayerSettings">{children}</div>;
};

LayerSettings.propTypes = {
	children: PropTypes.node,
};

export default LayerSettings;
