import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Action from '../../../../state/Action';
import Presentation from './presentation';

const mapStateToProps = state => {
	const activeViewKey = Select.views.getActiveKey(state);
	const views = Select.unhab.getViews(state);
	return {
		activeViewKey,
		views,
	};
};

const mapDispatchToProps = () => {
	return dispatch => {
		return {
			onViewKeyChange: option => {
				dispatch(Action.router.updateAppUrl('app', {viewKey: option.value}));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
