import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import {statisticsGroupsAttributeSets} from '../../../constants/app';

const mapStateToProps = state => {
	return {
		sections:
			Select.unhab.datasets.filterGivenAttributeSetsByActiveDatasetsAttributes(
				state,
				statisticsGroupsAttributeSets,
			),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
