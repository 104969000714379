import {commonSelectors} from '@gisatcz/ptr-state';

const getSubstate = state => state.xCubeFeatures;
const getCurrentPointFeatureIndex = state =>
	state.xCubeFeatures.currentPointFeatureIndex;
const getByKey = commonSelectors.getByKey(getSubstate);
const getByKeys = commonSelectors.getByKeys(getSubstate);
const getAllAsObject = commonSelectors.getAllAsObject(getSubstate);
const getAll = commonSelectors.getAll(getSubstate);

export default {
	getAllAsObject,
	getAll,
	getByKey,
	getByKeys,
	getCurrentPointFeatureIndex,
};
