import ActionTypes from '../../constants/ActionTypes';
import limassolAreas from '../../data/layers/limassolAreas';

function addPublicGreenAreas() {
	return dispatch => {
		dispatch(
			addAsObject(
				limassolAreas?.options?.features,
				limassolAreas?.options?.fidColumnName,
			),
		);
	};
}

function addAsObject(features, fidColumnName) {
	return dispatch => {
		if (features?.length && fidColumnName) {
			const asObject = {};
			features.map(
				feature => (asObject[feature.properties[fidColumnName]] = feature),
			);
			dispatch(actionAdd(asObject));
		}
	};
}

const actionAdd = features => {
	return {
		type: ActionTypes.XCUBE_FEATURES.ADD_AS_OBJECT,
		features,
	};
};

const actionIncrementIndex = () => {
	return {
		type: ActionTypes.XCUBE_FEATURES.INCREMENT_INDEX,
	};
};

const actionUpdate = features => {
	return {
		type: ActionTypes.XCUBE_FEATURES.UPDATE,
		features,
	};
};

function update(features, fidColumnName) {
	return dispatch => {
		if (features?.length && fidColumnName) {
			const asObject = {};
			features.map(
				feature => (asObject[feature.properties[fidColumnName]] = feature),
			);
			dispatch(actionUpdate(asObject));
		}
	};
}

export default {
	addPublicGreenAreas,
	addAsObject,
	incrementIndex: actionIncrementIndex,
	update,
};
