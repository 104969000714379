import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		image: Select.xCubeLayers.getColorBarImageByName(
			state,
			ownProps.layerDefinition?.metadata?.legend?.name,
		),
		type: Select.xCubeLayers.getLegendTypeByName(
			state,
			ownProps.layerDefinition?.metadata?.legend?.name,
		),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
