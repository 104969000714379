import PropTypes from 'prop-types';
import './style.scss';

const FormFooter = ({children}) => {
	return <div className="ptr-FormFooter">{children}</div>;
};

FormFooter.propTypes = {
	children: PropTypes.node.isRequired,
};

export default FormFooter;
