import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../state/Action';
import Select from '../../../state/Select';

const mapStateToProps = state => {
	return {
		pointAddingEnabled: Select.components.get(
			state,
			'AddPointControl',
			'active',
		),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.xCubeFeatures.addPublicGreenAreas());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
