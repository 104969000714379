import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		availableLayers: Select.xCubeLayers.getAllGrouped(state),
		activeLayers: Select.maps.getMapLayersStateByMapKey(state, ownProps.mapKey),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onMount: () => {
			dispatch(Action.xCubeLayers.use());
			dispatch(Action.xCubeLayers.useColorBars());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
